<template>
  <div class="bg-white rounded-lg flex justify-around gap-x-1.5 py-2 px-6 shadow">
    <Tooltip :title="MsgColor" :overlayClassName="[showListColors && 'opacity-0 pointer-events-none', 'transition-opacity']">
      <Popover v-model:visible="showListColors" trigger="click blur">
        <template #content>
          <div class="grid grid-cols-2 gap-2.5">
            <div
              v-for="(color, index) in colorsList"
              :key="index"
              :style="`background-color: ${color}`"
              class="w-5 h-5 rounded-full shadow cursor-pointer transition-transform border border-slate-200 hover:scale-125"
              @click="
                updateColor(color);
                hideListColors();
              "
            ></div>
          </div>
        </template>
        <div class="relative flex justify-center items-center rounded-lg cursor-pointer mr-2.5">
          <img :src="require(`@/assets/tools/ColorSelectScribble.svg`)" alt="Color Icon" />
          <div
            class="absolute w-3 h-3 bottom-1 right-0 border border-slate-200 rounded-full shadow"
            :style="`background-color: ${controlTextState.fill}`"
          ></div>
        </div>
      </Popover>
    </Tooltip>
    <CustomSelect ref="customSelectFontCompRef" class="w-60" v-model:selectedItem="selectedFont" :items="fontFamilies" />
    <CustomSelect ref="customSelectSizeCompRef" class="w-24" v-model:selectedItem="selectedSize" :items="fontSizes" />
    <Tooltip :title="MsgClose" placement="bottom">
      <div class="h-8 w-8 text-gs-purple cursor-pointer flex items-center ml-0.5 justify-end self-center" v-on:click="close">
        <CloseSquareIcon />
      </div>
    </Tooltip>
  </div>
</template>
<script lang="ts" src="./text-control.ts"></script>
