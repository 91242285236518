import { AnnotationModel } from "@/models";
import { FabricObject } from "@/ws";
import { ActionContext, ActionTree } from "vuex";
import { AnnotationState, LastFabricUpdated, Pointer, UserShape } from "./state";
import { IEditLine } from "@/store/annotation/mutations";
import { defineVuexName, vuexName, VuexNames } from "@/store/utils";

export interface AnnotationActionInterface<S, R> {
  setInfo(s: ActionContext<S, R>, p: AnnotationModel): void;
  setPointer(s: ActionContext<S, R>, p: Pointer): void;
  setMode(s: ActionContext<S, R>, p: { mode: number }): void;
  addShape(s: ActionContext<S, R>, p: string): void;
  setTeacherBrushes(s: ActionContext<S, R>, p: Array<string>): void;
  setOneTeacherStrokes(s: ActionContext<S, R>, p: Array<string>): void;
  setClearBrush(s: ActionContext<S, R>, p: {}): void;
  setTeacherAddShape(s: ActionContext<S, R>, p: { teacherShapes: Array<UserShape> }): void;
  setClearOneTeacherDrawsStrokes(s: ActionContext<S, R>, p: {}): void;
}

export interface AnnotationAction<S, R> extends ActionTree<S, R>, AnnotationActionInterface<S, R> {}

const actions: ActionTree<AnnotationState, any> = {
  setInfo({ commit }, p: AnnotationModel) {
    commit("setInfo", p);
  },
  setPointer({ commit }, p: Pointer) {
    commit("setPointer", p);
  },
  setMode({ commit }, p: { mode: number }) {
    commit("setMode", p);
  },
  addShape({ commit, rootGetters }, p: string) {
    if (rootGetters["studentRoom/getStudentModeOneId"]) {
      commit("setOneTeacherDrawsStrokes", p);
    } else if (rootGetters["teacherRoom/getStudentModeOneId"]) {
      commit("setOneTeacherDrawsStrokes", p);
    } else {
      commit("addShape", p);
    }
  },
  setTeacherBrushes({ commit }, p: Array<string>) {
    commit("setTeacherBrushes", p);
  },
  setOneTeacherStrokes({ commit }, p: Array<string>) {
    commit("setOneTeacherStrokes", p);
  },
  setClearBrush({ commit }, p: {}) {
    commit("setClearBrush", p);
  },
  clearAnnotationState({ commit }, p: {}) {
    commit("clearAnnotationState", p);
  },
  setTeacherAddShape({ commit, rootGetters }, p: { teacherShapes: Array<UserShape> }) {
    if (rootGetters["studentRoom/getStudentModeOneId"]) {
      commit("setOneTeacherAddShape", p);
    } else if (rootGetters["teacherRoom/getStudentModeOneId"]) {
      commit("setOneTeacherAddShape", p);
    } else {
      commit("setTeacherAddShape", p);
    }
  },
  setClearOneTeacherDrawsStrokes({ commit }, p: {}) {
    commit("setClearOneTeacherDrawsStrokes", p);
  },
  setLastFabricUpdated({ commit }, p: LastFabricUpdated | null) {
    commit("setLastFabricUpdated", p);
  },
  setFabricsInDrawing({ commit }, p: FabricObject[]) {
    commit("setFabricsInDrawing", p);
  },
  setFabricsInOneMode({ commit }, p: FabricObject[]) {
    commit("setFabricsInOneMode", p);
  },
  setImgDimension({ commit }, payload: { width?: number; height?: number }) {
    commit("setImgDimension", payload);
  },
  setDrawPencil({ commit, rootState }, payload: string) {
    commit("setDrawPencil", {
      ...JSON.parse(payload),
      ratio: rootState.lesson.zoomRatio,
    });
  },
  setDrawLaser({ commit }, payload: string) {
    commit("setDrawLaser", { ...JSON.parse(payload) });
  },
  clearPencilPath({ commit }) {
    commit("clearPencilPath");
  },
  setFabricObjects({ commit, rootGetters }, payload: FabricObject) {
    if (rootGetters["studentRoom/getStudentModeOneId"] || rootGetters["teacherRoom/getStudentModeOneId"]) {
      commit("setOneToOneFabricObjects", payload);
    } else {
      commit("setFabricObjects", payload);
    }
  },
  removeLine({ commit, rootGetters }, payload: string) {
    const isOneModeActive = rootGetters["studentRoom/getStudentModeOneId"] || rootGetters["teacherRoom/getStudentModeOneId"];
    if (isOneModeActive) {
      commit("removeOneModeLine", payload);
    } else {
      commit("removeLine", payload);
    }
  },
  editLine({ commit, rootGetters }, payload: IEditLine) {
    const isOneModeActive = rootGetters["studentRoom/getStudentModeOneId"] || rootGetters["teacherRoom/getStudentModeOneId"];
    if (isOneModeActive) {
      commit("editOneModeLine", payload);
    } else {
      commit("editLine", payload);
    }
  },
  async [defineVuexName(VuexNames.ANNOTATION.DISPATCHES.REMOVE_SHAPE_BY_OID)]({ commit, rootGetters }, objectId: string) {
    commit(vuexName(VuexNames.ANNOTATION.COMMITS.REMOVE_SHAPE, false), {
      objectId,
      isOneToOne: rootGetters["studentRoom/getStudentModeOneId"] || rootGetters["teacherRoom/getStudentModeOneId"],
    });
  },
};
export default actions;
