import { GLContentService } from "../content.service";
import { LessonServiceInterface } from "./interface";
import { LessonModel } from "./model";
class LessonServiceClass extends GLContentService implements LessonServiceInterface {
  getLessonByUnit(unit: number): Promise<LessonModel[]> {
    return this.get(`versions/lessonPlane/${unit}`);
  }

  async getMediaUrl(token: string, mediaId: string): Promise<string> {
    const result = await fetch(`${process.env.VUE_APP_API_PREFIX}content/v1/resource/GetDownloadMediaUrl?mediaId=${mediaId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.json();
  }

  async fetchDRMToken(mediaId: string): Promise<string> {
    try {
      const result = await fetch(`${process.env.VUE_APP_FETCH_DRM_TOKEN_URL}/${mediaId}`, {
        method: "GET",
      });
      if (!result.ok) {
        throw new Error(`HTTP error! status: ${result.status}`);
      }
      return await result.json();
    } catch (error) {
      console.error("Failed to fetch DRM token:", error);
      throw error;
    }
  }
}

export const LessonService = new LessonServiceClass();
