import { createDefaultMediaError, MediaDeviceError } from "@/agora/utils";

export enum VideoCallingQuality {
  "180p_4" = "180p_4",
  "480p_1" = "480p_1",
  "720p_1" = "720p_1",
}

export interface CallingState {
  callingUserIds: string[];
  videoUserIds: string[];
  audioUserIds: string[];
  enabledAgoraNoiseCancelling: boolean;
  postLocalUserReconnectedIntervalId?: number;
  cameraError: MediaDeviceError;
  microphoneError: MediaDeviceError;
  speakerError: MediaDeviceError;
  isJoinedSession: boolean;
  videoCallingQuality: VideoCallingQuality;
}

export const initCallingModuleState = () => ({
  callingUserIds: [],
  videoUserIds: [],
  audioUserIds: [],
  enabledAgoraNoiseCancelling: true,
  postLocalUserReconnectedIntervalId: undefined,
  cameraError: createDefaultMediaError(),
  microphoneError: createDefaultMediaError(),
  speakerError: createDefaultMediaError(),
  isJoinedSession: false,
  videoCallingQuality: VideoCallingQuality["180p_4"],
});

const state: CallingState = initCallingModuleState();

export default state;
