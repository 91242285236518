import { TeacherRoomManager } from "@/manager/room/teacher.manager";
import { ClassModel, RoomModel, TeachingMode } from "@/models";
import { GLError } from "@/models/error.model";
import { UserModel } from "@/models/user.model";
import { HelperState, ParamsToJoinCurSessionInterface, StudentState, TeacherState } from "../interface";
import { ClassAction } from "../student/state";

const DEFAULT_LESSON = 0;

export interface TeacherRoomState {
  info?: RoomModel;
  user?: UserModel;
  teacher?: TeacherState;
  students: Array<StudentState>;
  helper?: HelperState;
  manager?: TeacherRoomManager;
  classes: Array<ClassModel>;
  teachingMode: TeachingMode;
  error: GLError | null;
  cameraLock: boolean;
  classAction: ClassAction;
  idOne: string;
  idOneWithAnotherTeacher: string;
  speakingUsers: Array<string>;
  //* Determine local user is disconnect or not */
  isDisconnected: boolean;
  listStudentLowBandWidth: string[];
  currentLesson: number;
  currentUnit: number;
  isShowWhiteboard: boolean;
  isTeacherUseOnly: boolean;
  mediaState: boolean;
  paramsToJoinCurSession: ParamsToJoinCurSessionInterface;
  //* For Helper to determine main teacher is disconnect or not */
  teacherIsDisconnected: boolean;
  isSessionEnded: boolean;
}
export const initState = (): TeacherRoomState => ({
  info: undefined,
  user: undefined,
  teacher: undefined,
  helper: undefined,
  students: [],
  manager: undefined,
  classes: [],
  teachingMode: TeachingMode.CloseLessonPlan,
  error: null,
  cameraLock: false,
  classAction: ClassAction.DEFAULT,
  idOne: "",
  idOneWithAnotherTeacher: "",
  speakingUsers: [],
  isDisconnected: !navigator.onLine,
  listStudentLowBandWidth: [],
  currentLesson: DEFAULT_LESSON,
  currentUnit: DEFAULT_LESSON,
  isShowWhiteboard: false,
  isTeacherUseOnly: false,
  mediaState: false,
  paramsToJoinCurSession: {
    deviceId: "",
    groupId: "",
  },
  teacherIsDisconnected: false,
  isSessionEnded: false,
});
const state: TeacherRoomState = initState();

export default state;
