import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-contrib-eme";
import { LessonService } from "@/services";
import { Logger } from "@/utils/logger";
export default defineComponent({
  emits: ["define-video-ref"],
  props: {
    url: {
      type: String,
      default: "",
    },
    mediaId: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const videoPlayer = ref<HTMLElement | null>(null);
    let player: any = null;
    onMounted(async () => {
      try {
        const token = await LessonService.fetchDRMToken(props.mediaId);
        if (!videoPlayer.value) return;
        player = videojs(videoPlayer.value, {
          controls: true,
          autoplay: false,
          preload: "auto",
        });

        player.eme();

        player.ready(() => {
          player!.src({
            src: props.url,
            type: "application/x-mpegURL", //m3u8
            // type: "application/dash+xml", //mpd
            keySystems: {
              //PlayReady API (Windows/Edge/IE):
              "com.microsoft.playready": {
                url: "https://drm-playready-licensing.axprod.net/AcquireLicense",
                licenseHeaders: {
                  "X-AxDRM-Message": token,
                },
              },
              // //Widevine API (Chrome/Android):
              "com.widevine.alpha": {
                url: "https://drm-widevine-licensing.axprod.net/AcquireLicense",
                licenseHeaders: {
                  "X-AxDRM-Message": token,
                },
              },
              // "com.apple.fps.1_0": {
              //   certificateUri: "https://vtb.axinom.com/FPScert/fairplay.cer",
              //   licenseUri: "https://drm-fairplay-licensing.axprod.net/AcquireLicense",
              //   licenseHeaders: {
              //     "X-AxDRM-Message": token,
              //   },
              //   // getContentId: function (emeOptions, initData) {
              //   //   return new TextDecoder().decode(initData.filter(item => item !== 0 && item !== 150))
              //   // },
              // },
              // 'org.w3.clearkey': {
              //   url: "https://drm-clearkey-licensing.axprod.net/AcquireLicense",
              //   licenseHeaders: {
              //     "X-AxDRM-Message": token,
              //   },
              // },
            },
          });

          player!.play();
        });

        player.on("error", (error: unknown) => {
          console.error("Video.js error:", error);
        });
      } catch (e) {
        Logger.error(e);
      }
    });

    onBeforeUnmount(() => {
      if (player) {
        player.dispose();
      }
    });
    return { videoPlayer };
  },
});
