/**
 * This function is used to get the full name of an action.
 *
 * @param {string} name - The name of the action, expected to be in the format `module/action`.
 * @param {boolean} [withModuleName=false] - A boolean indicating whether the module name should be included in the returned string.
 *
 * @returns {string} If `withModuleName` is true, the function returns the `name` as it is. If `withModuleName` is false, the function returns the `name` without the module prefix.
 */
export const vuexName = (name: string, withModuleName = true): string => (withModuleName ? name : name.split("/").slice(1).join("/"));

export const defineVuexName = (name: string) => name.split("/").slice(1).join("/");

const createModuleActions = (module: string) => {
  return function createAction(action: string): string {
    return `${module}/${action}`;
  };
};

const createLessonAction = createModuleActions("lesson");
const createClassTeachingAction = createModuleActions("classTeaching");
const createTeacherTeachingAction = createModuleActions("teacherTeaching");
const createTeacherAction = createModuleActions("teacher");
const createCallingAction = createModuleActions("calling");
const createTeacherRoomAction = createModuleActions("teacherRoom");
const createStudentRoomAction = createModuleActions("studentRoom");
const createAnnotationAction = createModuleActions("annotation");
const createModalAction = createModuleActions("modal");

export const VuexNames = {
  LESSON: {
    DISPATCHES: {
      PROCESS_VISIBLE_TARGETS_DATA_FROM_API: createLessonAction("PROCESS_VISIBLE_TARGETS_DATA_FROM_API"),
      PROCESS_VISIBLE_TARGET_BY_FROM_MESSAGE: createLessonAction("PROCESS_VISIBLE_TARGET_BY_FROM_MESSAGE"),
      TOGGLE_VISIBLE_TARGET: createLessonAction("TOGGLE_VISIBLE_TARGET"),
      TOGGLE_ALL_VISIBLE_TARGETS: createLessonAction("TOGGLE_ALL_VISIBLE_TARGETS"),
      REQUEST_TOGGLE_TARGET: createLessonAction("REQUEST_TOGGLE_TARGET"),
      REQUEST_TOGGLE_ALL_TARGET: createLessonAction("REQUEST_TOGGLE_ALL_TARGET"),
      // more here ...
    },
    COMMITS: {
      SET_VISIBLE_TARGET_TAGS: createLessonAction("SET_VISIBLE_TARGET_TAGS"),
      CLEAR_EXPOSURE: createLessonAction("CLEAR_EXPOSURE"),
      // more here ...
    },
    GETTERS: {
      GET_VISIBLE_TARGET_TAGS: createLessonAction("GET_VISIBLE_TARGET_TAGS"),
      GET_ZOOM_RATIO: createLessonAction("GET_ZOOM_RATIO"),
      // more here ...
    },
  },
  TEACHER_ROOM: {
    DISPATCHES: {
      MANAGE_REMOTE_USER_VIDEO_AND_AUDIO_SUBSCRIPTIONS: createTeacherRoomAction("MANAGE_REMOTE_USER_VIDEO_AND_AUDIO_SUBSCRIPTIONS"),
      // more here ...
    },
    GETTERS: {
      GET_ONE_TO_ONE_STUDENT_ID: createTeacherRoomAction("GET_ONE_TO_ONE_STUDENT_ID"),
      GET_HELPER_ONE_TO_ONE_STUDENT_ID: createTeacherRoomAction("GET_HELPER_ONE_TO_ONE_STUDENT_ID"),
      GET_IS_TEACHER_ALLOW_STUDENT_SEE_HELPER_VIDEO: createTeacherRoomAction("GET_IS_TEACHER_ALLOW_STUDENT_SEE_HELPER_VIDEO"),
      // more here ...
    },
  },
  STUDENT_ROOM: {
    DISPATCHES: {
      MANAGE_REMOTE_USER_VIDEO_AND_AUDIO_SUBSCRIPTIONS: createStudentRoomAction("MANAGE_REMOTE_USER_VIDEO_AND_AUDIO_SUBSCRIPTIONS"),
      // more here ...
    },
    GETTERS: {
      GET_ONE_TO_ONE_STUDENT_ID: createStudentRoomAction("GET_ONE_TO_ONE_STUDENT_ID"),
      GET_HELPER_ONE_TO_ONE_STUDENT_ID: createStudentRoomAction("GET_HELPER_ONE_TO_ONE_STUDENT_ID"),
      GET_IS_TEACHER_ALLOW_STUDENT_SEE_HELPER_VIDEO: createStudentRoomAction("GET_IS_TEACHER_ALLOW_STUDENT_SEE_HELPER_VIDEO"),
      GET_IS_SHOW_CLASSMATE_VIDEOS: createStudentRoomAction("GET_IS_SHOW_CLASSMATE_VIDEOS"),
      // more here ...
    },
  },
  CLASS_TEACHING: {
    COMMITS: {
      SET_INITIAL_CLASS_DATA_SNAPSHOT: createClassTeachingAction("SET_INITIAL_CLASS_DATA_SNAPSHOT"),
      SET_IS_SOCKET_ATTEMPTED_RECONNECTING: createClassTeachingAction("SET_IS_SOCKET_ATTEMPTED_RECONNECTING"),
      SET_IS_INIT_CLASS_FAILED: createClassTeachingAction("SET_IS_INIT_CLASS_FAILED"),
      // more here ...
    },
    GETTERS: {
      GET_INITIAL_CLASS_DATA_SNAPSHOT_EXPOSURE_ID: createClassTeachingAction("GET_INITIAL_CLASS_DATA_SNAPSHOT_EXPOSURE_ID"),
      GET_IS_SOCKET_ATTEMPTED_RECONNECTING: createClassTeachingAction("GET_IS_SOCKET_ATTEMPTED_RECONNECTING"),
      GET_IS_INIT_CLASS_FAILED: createClassTeachingAction("GET_IS_INIT_CLASS_FAILED"),
      GET_IS_INDEPENDENT_MODE_ENABLED: createClassTeachingAction("GET_IS_INDEPENDENT_MODE_ENABLED"),
      GET_IS_MUTE_TEACHER_AUDIO_WHEN_ONE_TO_ONE_WITH_HELPER: createClassTeachingAction("GET_IS_MUTE_TEACHER_AUDIO_WHEN_ONE_TO_ONE_WITH_HELPER"),
      // more here ...
    },
  },
  TEACHER_TEACHING: {
    DISPATCHES: {
      TOGGLE_INDEPENDENT_MODE: createTeacherTeachingAction("TOGGLE_INDEPENDENT_MODE"),
    },
  },
  TEACHER: {
    DISPATCHES: {
      UPDATE_ONGOING_SESSION: createTeacherAction("UPDATE_ONGOING_SESSION"),
    },
    COMMITS: {
      //
      SET_ONGOING_SESSION: createTeacherAction("SET_ONGOING_SESSION"),
    },
    GETTERS: {
      GET_ONGOING_SESSION: createTeacherAction("GET_ONGOING_SESSION"),
    },
  },
  CALLING: {
    DISPATCHES: {
      FETCH_AGORA_SETTINGS: createCallingAction("FETCH_AGORA_SETTINGS"),
    },
    COMMITS: {
      SET_VIDEO_USER_IDS: createCallingAction("SET_VIDEO_USER_IDS"),
      SET_AUDIO_USER_IDS: createCallingAction("SET_AUDIO_USER_IDS"),
      SET_IS_JOINED_SESSION: createCallingAction("SET_IS_JOINED_SESSION"),
      SET_VIDEO_CALLING_QUALITY: createCallingAction("SET_VIDEO_CALLING_QUALITY"),
      RESET_REMOTE_USERS_STATE: createCallingAction("RESET_REMOTE_USERS_STATE"),
    },
    GETTERS: {
      GET_VIDEO_USER_IDS: createCallingAction("GET_VIDEO_USER_IDS"),
      GET_AUDIO_USER_IDS: createCallingAction("GET_AUDIO_USER_IDS"),
      GET_IS_JOINED_SESSION: createCallingAction("GET_IS_JOINED_SESSION"),
      GET_VIDEO_CALLING_QUALITY: createCallingAction("GET_VIDEO_CALLING_QUALITY"),
    },
  },
  ANNOTATION: {
    DISPATCHES: {
      REMOVE_SHAPE_BY_OID: createAnnotationAction("REMOVE_SHAPE"),
    },
    COMMITS: {
      REMOVE_SHAPE: createAnnotationAction("REMOVE_SHAPE"),
    },
    GETTERS: {
      //
    },
  },
  MODAL: {
    DISPATCHES: {
      INFORM_DEVICE_WARNING: createModalAction("INFORM_DEVICE_WARNING"),
    },
    COMMITS: {
      //
    },
    GETTERS: {
      NAME: createModalAction("NAME"),
    },
  },
};
