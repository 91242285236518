import { ActionTree } from "vuex";
import { ModalState } from "./state";
import { h } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { CommonLocale, IndependentLocales, MediaDeviceErrors, SessionReplacementLocales, Survey } from "@/locales/localeid";
import { Paths } from "@/utils/paths";
import router from "@/router";
import CustomerResponseModal from "../../views/teacher-home/components/customer-response-modal/calling-quality-survey.vue";
import DeviceWarningContent from "@/components/common/device-warning-content/device-warning-content.vue";
import { defineVuexName, vuexName, VuexNames } from "../utils";
import { CameraCustomErrorCode, MicrophoneCustomErrorCode } from "@/agora/utils";

export enum DynamicModalNames {
  CameraWarning = "CameraWarning",
  MicrophoneWarning = "MicrophoneWarning",
}
const actions: ActionTree<ModalState, any> = {
  async warningIdpModeBeforeExecute({ commit, dispatch }, callback: () => Promise<void>) {
    commit("open", {
      view: h("div", { innerHTML: fmtMsg(IndependentLocales.StopWarning) }),
      title: fmtMsg(IndependentLocales.StopMode),
      actions: [
        {
          label: fmtMsg(CommonLocale.CommonOkButtonText),
          callback: async () => {
            commit("close");
            await dispatch(vuexName(VuexNames.TEACHER_TEACHING.DISPATCHES.TOGGLE_INDEPENDENT_MODE), null, { root: true });
            await callback();
          },
        },
      ],
    });
  },
  async confirmReplacementSession({ commit }, callback: () => Promise<void>) {
    commit("open", {
      view: h("div", { innerHTML: fmtMsg(SessionReplacementLocales.Description) }),
      title: fmtMsg(SessionReplacementLocales.Title),
      actions: [
        {
          label: fmtMsg(CommonLocale.CommonOkButtonText),
          callback: async () => {
            commit("close");
            await callback();
          },
        },
        {
          callback: async () => {
            await router.push(Paths.Home);
          },
        },
      ],
    });
  },
  showCustomerFeedbackSurvey({ commit }) {
    commit("open", {
      view: CustomerResponseModal,
      title: fmtMsg(Survey.Title),
      props: { closeable: false, isFooterHidden: true },
    });
  },
  async [defineVuexName(VuexNames.MODAL.DISPATCHES.INFORM_DEVICE_WARNING)](
    { commit },
    { code, callback, isCamera }: { code: string; title: string; isCamera: boolean; callback: () => Promise<void> },
  ) {
    let title = "";

    if (isCamera) {
      switch (code) {
        case CameraCustomErrorCode.CAMERA_DEVICE_IN_USE:
          title = fmtMsg(MediaDeviceErrors.CameraInUse);
          break;
        case CameraCustomErrorCode.CAMERA_PERMISSION_DENIED:
          title = fmtMsg(MediaDeviceErrors.CameraPermissionDenied);
          break;
        default:
          title = fmtMsg(MediaDeviceErrors.CameraInUse);
          break;
      }
    } else {
      switch (code) {
        case MicrophoneCustomErrorCode.MICROPHONE_DEVICE_IN_USE:
          title = fmtMsg(MediaDeviceErrors.MicrophoneInUse);
          break;
        case MicrophoneCustomErrorCode.MICROPHONE_PERMISSION_DENIED:
          title = fmtMsg(MediaDeviceErrors.MicrophonePermissionDenied);
          break;
        default:
          title = fmtMsg(MediaDeviceErrors.MicrophoneInUse);
          break;
      }
    }

    commit("open", {
      view: h(DeviceWarningContent, { code }),
      title: title,
      name: isCamera ? DynamicModalNames.CameraWarning : DynamicModalNames.MicrophoneWarning,
      actions: [
        {
          label: fmtMsg(MediaDeviceErrors.TryAgain),
          callback: async () => {
            await callback();
          },
        },
        {
          callback: async () => {
            commit("close");
          },
        },
      ],
    });
  },
  close({ commit }) {
    commit("close");
  },
};

export default actions;
