import { GLServiceBase } from "vue-glcommonui";
import { HelperServiceInterface } from "@/services";
import {
  CancelJoinAsHelperResponse,
  GetSessionStatusResponse,
  HelperExitResponse,
  HelperRequestTeachingResponse,
  JoinSessionAsHelperResponse,
  TeacherAcceptHelperResponse,
  TeacherDenyHelperResponse,
  TeacherRemoveHelperResponse,
  TeacherToggleHelperVideo,
} from "./model";
import { cloneDeep } from "lodash";
import { FetchSessionDtoResModel } from "@/services";
import { store } from "@/store";
import { UserRequestModel } from "@/ws/teacher";

export const transformOneToOneDataServeHelper = (model: FetchSessionDtoResModel) => {
  const cloned = cloneDeep(model);
  if (!cloned.data) {
    return cloned;
  }
  cloned.data.oneToOneWithAnotherTeacherStudentId = cloned.data.oneAndOneDto?.studentId;
  if (cloned.data.oneAndOneDto && cloned.data.helperOneAndOneDto) {
    cloned.data.oneAndOneDto.studentId = cloned.data.helperOneAndOneDto.studentId;
  }
  cloned.data.oneAndOneDto = cloned.data.helperOneAndOneDto;
  return cloned;
};
export const transformOneToOneDataServeTeacher = (model: FetchSessionDtoResModel) => {
  const cloned = cloneDeep(model);
  if (!cloned.data) {
    return cloned;
  }
  cloned.data.oneToOneWithAnotherTeacherStudentId = cloned.data.helperOneAndOneDto?.studentId || "";
  return cloned;
};
class HelperServiceClass extends GLServiceBase<any, any> implements HelperServiceInterface {
  get userRequestModel(): UserRequestModel {
    return {
      userId: store.getters["auth/getLoginInfo"]?.profile?.sub,
      sessionId: store.getters["classTeaching/getSessionId"],
    };
  }
  serviceRoute = { prefix: "remote/v1" };
  getSessionStatus(groupId: string): Promise<GetSessionStatusResponse> {
    return this.get("helper/session-status", { groupId });
  }
  joinSessionAsHelper(groupId: string, deviceId: string): Promise<JoinSessionAsHelperResponse> {
    return this.get("helper/request-join-session", { groupId, deviceId });
  }
  cancelJoinAsHelperRequest(groupId: string): Promise<CancelJoinAsHelperResponse> {
    return this.update(`helper/cancel-request?groupId=${groupId}`);
  }
  fetchSessionDataAsHelper(groupId: string, deviceId = ""): Promise<FetchSessionDtoResModel> {
    return this.get("helper/session-data", { groupId, deviceId }).then((res) => transformOneToOneDataServeHelper(res as FetchSessionDtoResModel));
  }
  exitSession(): Promise<HelperExitResponse> {
    return this.update("helper/exit-session");
  }
  teacherAcceptHelper(helperId: string, helperName: string, deviceId: string): Promise<TeacherAcceptHelperResponse> {
    const { sessionId } = this.userRequestModel;
    return this.create("teacher/accept-helper", { helperId, helperName, deviceId, sessionId });
  }
  teacherDenyHelper(helperId: string): Promise<TeacherDenyHelperResponse> {
    const { sessionId } = this.userRequestModel;
    return this.create(`teacher/deny-helper/${helperId}/session/${sessionId}`);
  }
  teacherToggleHelperVideo(show: boolean): Promise<TeacherToggleHelperVideo> {
    const { sessionId } = this.userRequestModel;
    return this.create(`teacher/toggle-helper-video/${show}/session/${sessionId}`);
  }
  teacherRemoveHelper(helperId: string): Promise<TeacherRemoveHelperResponse> {
    const { sessionId } = this.userRequestModel;
    return this.get(`teacher/remove-helper/${helperId}/session/${sessionId}`);
  }
  takeTeacherRole(): Promise<HelperRequestTeachingResponse> {
    const { sessionId } = this.userRequestModel;
    return this.create(`helper/take-teacher-role/session/${sessionId}`);
  }
}

export const HelperService = new HelperServiceClass();
