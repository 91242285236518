import { computed, createVNode, defineComponent, ref, watch } from "vue";
import { CloseOutlined, DownOutlined, ExclamationCircleOutlined, LoadingOutlined, UpOutlined } from "@ant-design/icons-vue";
import { Button, Modal } from "ant-design-vue";
import { HelperState } from "@/store/room/interface";
import { useStore } from "vuex";
import { fmtMsg } from "vue-glcommonui";
import { CommonLocale, HelperLocales, TeacherClassLocale } from "@/locales/localeid";
import { HelperService } from "@/services";
import { Logger } from "@/utils/logger";
import {
  VideoCameraIcon,
  VideoCameraSlashIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
  XMarkIcon,
  Cog6ToothIcon,
  EyeIcon,
  EyeSlashIcon,
  ArrowsPointingInIcon,
  WifiIcon,
} from "@heroicons/vue/20/solid";
import { generateAvatar } from "@/utils/utils";
import NameTag from "@/components/common/name-tag/name-tag.vue";
import { isUserPublishedAudio, isUserPublishedVideo } from "@/store/calling/utils";
import { vuexName, VuexNames } from "@/store/utils";
import { DeviceErrorTooltip } from "@/components/common/device-settings/components";
import { MediaDeviceError } from "@/agora/utils";
import { useDeviceToggling } from "@/hooks/useDeviceToggling";
import { DynamicModalNames } from "@/store/modal/actions";

export default defineComponent({
  name: "TeacherClassHelperCard",
  props: {
    isMinimized: Boolean,
    onClickMinimized: {
      type: Object as () => void,
      required: true,
    },
  },
  components: {
    DeviceErrorTooltip,
    LoadingOutlined,
    NameTag,
    CloseOutlined,
    DownOutlined,
    UpOutlined,
    Button,
    VideoCameraIcon,
    VideoCameraSlashIcon,
    SpeakerWaveIcon,
    SpeakerXMarkIcon,
    XMarkIcon,
    Cog6ToothIcon,
    EyeIcon,
    EyeSlashIcon,
    ArrowsPointingInIcon,
    WifiIcon,
  },
  setup() {
    const { getters, dispatch, commit } = useStore();
    const isDeviceSettingsModalShown = ref(false);
    const { toggleMicrophone, toggleCamera, isCameraToggling, isMicrophoneToggling } = useDeviceToggling();
    const helperInfo = computed<HelperState | undefined>(() => getters["teacherRoom/helperInfo"]);
    const dynamicModalName = computed<string>(() => getters[vuexName(VuexNames.MODAL.GETTERS.NAME)]);
    const isAudioPublished = computed<boolean>(() => isUserPublishedAudio({ userId: helperInfo.value?.id ?? "", gettersOrRootGetters: getters }));
    const isVideoPublished = computed<boolean>(() => isUserPublishedVideo({ userId: helperInfo.value?.id ?? "", gettersOrRootGetters: getters }));
    const isJoinedSession = computed<boolean>(() => getters[vuexName(VuexNames.CALLING.GETTERS.GET_IS_JOINED_SESSION)]);
    const isTeacherAllowStudentSeeHelperVideo = computed<boolean>(
      () => getters[vuexName(VuexNames.TEACHER_ROOM.GETTERS.GET_IS_TEACHER_ALLOW_STUDENT_SEE_HELPER_VIDEO)],
    );
    const helperId = computed(() => getters["teacherRoom/helperId"]);
    const isMeHelper = computed<boolean>(() => getters["teacher/isHelper"]);
    const oneAndOne = computed(() => getters["teacherRoom/getStudentModeOneId"]);
    const anotherTeacherIsInOneToOneMode = computed(() => !!getters["teacherRoom/getStudentModeOneWithAnotherTeacherId"]);
    const cameraError = computed<MediaDeviceError>(() => getters["calling/getCameraError"]);
    const microphoneError = computed<MediaDeviceError>(() => getters["calling/getMicrophoneError"]);
    const toggleHelperVideoLoading = ref(false);
    // Teacher hide or show helper video for everyone in the class
    const toggleHelperVideo = async (isShown = false) => {
      if (isTeacherAllowStudentSeeHelperVideo.value === isShown || toggleHelperVideoLoading.value) return;
      try {
        toggleHelperVideoLoading.value = true;
        await HelperService.teacherToggleHelperVideo(isShown);
      } catch (error) {
        Logger.error(error);
      }
      toggleHelperVideoLoading.value = false;
    };

    const onHelperToggleCamera = async () => {
      await toggleCamera(async () => {
        let isOff = false;
        if (isVideoPublished.value) {
          isOff = true;
        }
        await dispatch("teacherRoom/toggleHelperCamera", isOff);
      });
    };
    const onHelperToggleMicro = async () => {
      await toggleMicrophone(async () => {
        let isOff = false;
        if (isAudioPublished.value) {
          isOff = true;
        }
        await dispatch("teacherRoom/toggleHelperMicro", isOff);
      });
    };
    const updateCamOpen = async (isOpen: boolean) => {
      await toggleCamera(async () => {
        await dispatch("teacherRoom/toggleHelperCamera", !isOpen);
      });
    };

    const updateMicOpen = async (isOpen: boolean) => {
      await toggleMicrophone(async () => {
        await dispatch("teacherRoom/toggleHelperMicro", !isOpen);
      });
    };

    const openDeviceSettingsModal = () => {
      isDeviceSettingsModalShown.value = true;
    };

    const openDeviceWarningModal = async (isCamera: boolean) => {
      await dispatch(vuexName(VuexNames.MODAL.DISPATCHES.INFORM_DEVICE_WARNING), {
        code: isCamera ? cameraError.value.code : microphoneError.value.code,
        isCamera,
        callback: isCamera ? onHelperToggleCamera : onHelperToggleMicro,
      });
    };

    watch(isVideoPublished, async (val) => {
      if (val && dynamicModalName.value === DynamicModalNames.CameraWarning) {
        commit("modal/close");
      }
    });

    watch(isAudioPublished, async (val) => {
      if (val && dynamicModalName.value === DynamicModalNames.MicrophoneWarning) {
        commit("modal/close");
      }
    });

    const onRemoveHelper = () => {
      Modal.confirm({
        icon: createVNode(ExclamationCircleOutlined),
        content: ConfirmRemoveHelperText.value,
        okText: CommonYesText.value,
        cancelText: CommonNoText.value,
        onOk: async () => {
          await dispatch("teacherRoom/teacherRemoveHelper");
        },
      });
    };

    // TODO PRE-477: In action MANAGE_REMOTE_USER_VIDEO_AND_AUDIO_SUBSCRIPTIONS, set a new state which save all ids of video which are showing on screen, because showwing video is different with published video
    const isVideoShown = computed<boolean>(() => {
      return isMeHelper.value
        ? isVideoPublished.value
        : isVideoPublished.value && isTeacherAllowStudentSeeHelperVideo.value && !oneAndOne.value && !anotherTeacherIsInOneToOneMode.value;
    });

    const helperAvatar = computed<string>(() => generateAvatar(getters["teacherRoom/helperAvatar"]));

    const CommonYesText = computed(() => fmtMsg(CommonLocale.CommonYesButtonText));
    const CommonNoText = computed(() => fmtMsg(CommonLocale.CommonNoButtonText));
    const ConfirmRemoveHelperText = computed(() => fmtMsg(TeacherClassLocale.ConfirmRemoveHelper));
    const showHelperVideoText = computed(() => fmtMsg(HelperLocales.ShowVideoButton));
    const hideHelperVideoText = computed(() => fmtMsg(HelperLocales.HideVideoButton));
    return {
      helperInfo,
      isTeacherAllowStudentSeeHelperVideo,
      isMeHelper,
      isVideoPublished,
      isAudioPublished,
      onHelperToggleCamera,
      onHelperToggleMicro,
      updateCamOpen,
      updateMicOpen,
      openDeviceSettingsModal,
      isDeviceSettingsModalShown,
      onRemoveHelper,
      toggleHelperVideoLoading,
      toggleHelperVideo,
      showHelperVideoText,
      hideHelperVideoText,
      isVideoShown,
      helperAvatar,
      helperId,
      VideoCameraIcon,
      VideoCameraSlashIcon,
      SpeakerWaveIcon,
      SpeakerXMarkIcon,
      EyeIcon,
      EyeSlashIcon,
      cameraError,
      microphoneError,
      isJoinedSession,
      isCameraToggling,
      isMicrophoneToggling,
      openDeviceWarningModal,
    };
  },
});
