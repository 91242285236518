import { TeacherRoomManager } from "@/manager/room/teacher.manager";
import { ClassModel, RoomModel, TeachingMode } from "@/models";
import { GetterTree } from "vuex";
import { HelperState, InClassStatus, ParamsToJoinCurSessionInterface, StudentState, TeacherState } from "../interface";
import { ClassAction } from "../student/state";
import { TeacherRoomState } from "./state";
import { defineVuexName, VuexNames } from "@/store/utils";

const getters: GetterTree<TeacherRoomState, any> = {
  isConnected(state: TeacherRoomState): boolean {
    return !!state.manager?.WSClient.isConnected;
  },
  info(state: TeacherRoomState): RoomModel {
    return state.info as RoomModel;
  },
  sessionId(state: TeacherRoomState): string {
    return state.info?.id ?? "";
  },
  error(state: TeacherRoomState): any {
    return state.error;
  },
  classes(state: TeacherRoomState): Array<ClassModel> {
    return state.classes;
  },
  students(state: TeacherRoomState): Array<StudentState> {
    return state.students;
  },
  teacher(state: TeacherRoomState): TeacherState | undefined {
    return state.teacher as TeacherState;
  },
  teacherId(state: TeacherRoomState): string | undefined {
    return state.teacher?.id;
  },
  roomManager(state: TeacherRoomState): TeacherRoomManager {
    return state.manager as TeacherRoomManager;
  },
  teachingMode(state: TeacherRoomState): TeachingMode {
    return state.teachingMode;
  },
  classAction(state: TeacherRoomState): ClassAction {
    return state.classAction;
  },
  getStudentModeOneId(state: TeacherRoomState): string {
    return state.idOne;
  },
  getStudentModeOneWithAnotherTeacherId(state: TeacherRoomState): string {
    return state.idOneWithAnotherTeacher;
  },
  getTeacherOrHelperOneToOne(state: TeacherRoomState): boolean {
    return !!state.idOne || !!state.idOneWithAnotherTeacher;
  },
  oneStudentIds(state: TeacherRoomState): string[] {
    const studentIds = [];
    if (state.idOne) {
      studentIds.push(state.idOne);
    }
    if (state.idOneWithAnotherTeacher) {
      studentIds.push(state.idOneWithAnotherTeacher);
    }
    return studentIds;
  },
  studentIdsNotInOneToOneMode(state: TeacherRoomState): string[] {
    return state.students.filter((st) => st.id !== state.idOne && st.id !== state.idOneWithAnotherTeacher).map((s) => s.id);
  },
  getStudentModeOneWithAnotherTeacher(state: TeacherRoomState): StudentState | null {
    const id = state.idOneWithAnotherTeacher;
    const student = state.students.find((s) => s.status !== InClassStatus.LEFT && s.id === id);
    return student ?? null;
  },
  speakingUsers(state: TeacherRoomState): Array<string> {
    return state.speakingUsers;
  },
  isDisconnected(state: TeacherRoomState): boolean {
    return state.isDisconnected;
  },
  listStudentLowBandWidth(state: TeacherRoomState): string[] {
    return state.listStudentLowBandWidth;
  },
  currentLesson(state: TeacherRoomState): number {
    return state.currentLesson;
  },
  currentUnit(state: TeacherRoomState): number {
    return state.currentUnit;
  },
  isShowWhiteBoard(state: TeacherRoomState): boolean {
    return state.isShowWhiteboard;
  },
  isTeacherUseOnly(state: TeacherRoomState): boolean {
    return state.isTeacherUseOnly;
  },
  getMediaState(state: TeacherRoomState): boolean {
    return state.mediaState;
  },
  helperId(state: TeacherRoomState): string | undefined {
    return state.helper?.id;
  },
  helperInfo(state: TeacherRoomState): HelperState | undefined {
    return state.helper;
  },
  helperVideoStatus(state: TeacherRoomState): boolean {
    return state.helper?.isVideoShownByTeacher ?? false;
  },
  helperAvatar(state: TeacherRoomState): string {
    return state.helper?.avatar ?? "";
  },
  getParamsToJoinCurSession(state: TeacherRoomState): ParamsToJoinCurSessionInterface {
    return state.paramsToJoinCurSession;
  },
  signalRConnected(state: TeacherRoomState): boolean {
    return !!state.manager?.WSClient.isConnected;
  },
  teacherIsDisconnected(state: TeacherRoomState): boolean {
    return state.teacherIsDisconnected;
  },
  studentIdsInOneToOneMode(state: TeacherRoomState): string[] {
    const studentIds = [];
    if (state.idOne) {
      studentIds.push(state.idOne);
    }
    if (state.idOneWithAnotherTeacher) {
      studentIds.push(state.idOneWithAnotherTeacher);
    }
    return studentIds;
  },
  getStudentById: (state: TeacherRoomState): ((studentId: string) => StudentState | undefined) => {
    return (studentId: string) => state.students.find((student) => student.id === studentId);
  },
  // Note: In the teacher/helper state
  // If logged in with the role of Teacher:
  // idOne will be the ID of the student with whom the teacher is in a one-on-one mode;
  // idOneWithAnotherTeacher will be the ID of the student with whom the helper is in a one-on-one mode.

  // If logged in with the role of Helper:
  // idOne will be the ID of the student with whom the helper is in a one-on-one mode;
  // idOneWithAnotherTeacher will be the ID of the student with whom the teacher is in a one-on-one mode.
  [defineVuexName(VuexNames.TEACHER_ROOM.GETTERS.GET_ONE_TO_ONE_STUDENT_ID)](s: TeacherRoomState) {
    return s.idOne;
  },
  [defineVuexName(VuexNames.TEACHER_ROOM.GETTERS.GET_HELPER_ONE_TO_ONE_STUDENT_ID)](s: TeacherRoomState) {
    return s.idOneWithAnotherTeacher;
  },
  [defineVuexName(VuexNames.TEACHER_ROOM.GETTERS.GET_IS_TEACHER_ALLOW_STUDENT_SEE_HELPER_VIDEO)](s: TeacherRoomState): boolean {
    return s.helper?.isVideoShownByTeacher ?? false;
  },
};

export default getters;
