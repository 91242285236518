import IconSpeakerStop from "@/assets/icons/pause-button.png";
import IconSpeakerPlay from "@/assets/icons/play-button.png";
import { ClassSetUp, DeviceTesterLocale } from "@/locales/localeid";
import { Logger } from "@/utils/logger";
import { CheckOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import AgoraRTC, { ICameraVideoTrack, IMicrophoneAudioTrack } from "agora-rtc-sdk-ng";
import { Button, Divider, Progress, Row, Select, Skeleton, Space, Spin, Switch, Tooltip } from "ant-design-vue";
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { useStore } from "vuex";
import { isDesktopBrowser, logErrorDetails } from "@/utils/utils";
import { MicrophoneIcon, SpeakerWaveIcon, VideoCameraIcon, VideoCameraSlashIcon } from "@heroicons/vue/24/outline";
import { PauseCircleIcon, PlayCircleIcon } from "@heroicons/vue/24/solid";
import { DeviceErrorTooltip } from "./components";
import {
  filterAndSortMicrophones,
  filterAndSortSpeakers,
  mappingAgoraCameraError,
  MediaDeviceError,
  mappingAgoraMicrophoneError,
  MicrophoneCustomErrorCode,
  CameraCustomErrorCode,
  createDefaultMediaError,
} from "@/agora/utils";
import { LocalStorageHandler } from "@/utils/storage";
import { DEVICE_STATUS } from "@/utils/constant";
import { CallingDeviceType, DeviceStatus } from "@/agora";
import { store } from "@/store";
import { vuexName, VuexNames } from "@/store/utils";

export interface DeviceType {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
  isDefault?: boolean;
}
interface ILocalTracks {
  videoTrack: ICameraVideoTrack | null;
  audioTrack: IMicrophoneAudioTrack | null;
}
export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    },
    isCamEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMicEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMidSession: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    "onOpenCamChange",
    "onOpenMicChange",
    "onDeviceError",
    "onCameraStatusChange",
    "onMicrophoneStatusChange",
    "onCameraDeviceChange",
    "onMicrophoneDeviceChange",
    "onSpeakerDeviceChange",
    "onDevicesInitialized",
  ],
  components: {
    Tooltip,
    Progress,
    Select,
    SelectOption: Select.Option,
    Button,
    Skeleton,
    Divider,
    Row,
    Space,
    Spin,
    Switch,
    CheckOutlined,
    LoadingOutlined,
    VideoCameraIcon,
    SpeakerWaveIcon,
    MicrophoneIcon,
    PlayCircleIcon,
    PauseCircleIcon,
    VideoCameraSlashIcon,
    DeviceErrorTooltip,
  },
  setup(props, { emit }) {
    const { getters, dispatch, commit } = useStore();
    const deviceStatusStorageHandler = LocalStorageHandler.getInstance<DeviceStatus>(DEVICE_STATUS);
    const initialMicrophoneStatus = props.isMidSession ? deviceStatusStorageHandler.get()?.isMicrophoneEnabled ?? true : true;
    const initialCameraStatus = props.isMidSession ? deviceStatusStorageHandler.get()?.isCameraEnabled ?? true : true;
    const havePermissionCamera = computed(() => getters["havePermissionCamera"]);
    const havePermissionMicrophone = computed(() => getters["havePermissionMicrophone"]);
    const havePermissionSpeaker = computed(() => getters["havePermissionMicrophone"]); // Depend on mic because Agora doesn't support check speaker on Firefox
    const isOpenMic = ref<boolean>(props.isMicEnabled);
    const isCameraEnabled = ref<boolean>(false);
    const isMicrophoneEnabled = ref<boolean>(false);
    const localTracks = ref<ILocalTracks>({
      videoTrack: null,
      audioTrack: null,
    });
    const listMics = ref<DeviceType[]>([]);
    const listMicIds = ref<string[]>([]);
    const listCams = ref<DeviceType[]>([]);
    const listCamIds = ref<string[]>([]);
    const playerRef = ref();
    const currentMic = ref<DeviceType>();
    const currentMicLabel = ref();
    const currentCam = ref<DeviceType>();
    const volumeByPercent = ref(0);
    const volumeAnimation = ref();
    const videoElementId = "pre-local-player";
    const agoraMicError = ref(false);
    const agoraCamError = ref(false);
    const isConfigTrackingDone = ref(false);
    const microphoneError = ref<MediaDeviceError>(createDefaultMediaError());
    const cameraError = ref<MediaDeviceError>(createDefaultMediaError());
    const speakerError = ref<MediaDeviceError>(createDefaultMediaError());

    const audio = ref<any>(null);
    const listSpeakers = ref<DeviceType[]>([]);
    const listSpeakerIds = ref<string[]>([]);
    const currentSpeaker = ref<DeviceType>();
    const currentSpeakerLabel = ref();
    const isPlayingSound = ref(false);
    const isPlaySpeaker = ref(false);
    const speakerIcon = computed(() => (isPlaySpeaker.value ? IconSpeakerStop : IconSpeakerPlay));
    const activeCameraId = ref<string>();
    const activeMicrophoneId = ref<string>();
    const activeSpeakerId = ref<string>();
    const isCameraInitialized = ref(false);
    const isMicrophoneInitialized = ref(false);
    const isSpeakerInitialized = ref(false);
    const areAllMediaDevicesInitialized = computed(() => isCameraInitialized.value && isMicrophoneInitialized.value && isSpeakerInitialized.value);
    //#region Microphone Logic
    const setupMicrophone = async () => {
      try {
        AgoraRTC.onMicrophoneChanged = handleHotPluggingMicrophone;
        const availableMicrophones = await fetchAvailableMicrophones();
        if (availableMicrophones.length) {
          localTracks.value.audioTrack = await createMicrophoneTrack(availableMicrophones.map((mic) => mic.deviceId));
          await initializeMicrophoneSelection(availableMicrophones);
          const preferredMicrophoneId = getPreferredMicrophoneId(availableMicrophones);
          if (preferredMicrophoneId) {
            await selectPreferredMicrophone(preferredMicrophoneId);
          } else {
            switchMicrophoneStatus(initialMicrophoneStatus);
          }
        } else {
          if (!microphoneError.value.isError) {
            throw new Error(MicrophoneCustomErrorCode.MICROPHONE_NOT_FOUND);
          }
        }
      } catch (error) {
        await handleMicrophoneDisconnection(error);
        logErrorDetails(error);
      } finally {
        isMicrophoneInitialized.value = true;
      }
    };

    const getPreferredMicrophoneId = (availableMicrophones: DeviceType[]) => {
      const persistedId: string = getters["microphoneDeviceId"];
      const preferredMicrophone = availableMicrophones.find((microphone) => microphone.deviceId === persistedId);
      if (preferredMicrophone) {
        return preferredMicrophone.deviceId;
      }
      return null;
    };

    // Check if the preferred camera ID archived in the local storage is still available
    const selectPreferredMicrophone = async (preferredMicrophoneId: string) => {
      try {
        activeMicrophoneId.value = preferredMicrophoneId;
        if (localTracks.value.audioTrack) {
          await localTracks.value.audioTrack.setDevice(preferredMicrophoneId);
          switchMicrophoneStatus(initialMicrophoneStatus);
          microphoneError.value = createDefaultMediaError();
        }
      } catch (e) {
        await handleMicrophoneDisconnection(e);
      }
    };

    // Handle camera hot-plugging
    const handleHotPluggingMicrophone = async () => {
      await updateMicrophoneList();
    };
    // Fetch available cameras
    const fetchAvailableMicrophones = async (): Promise<DeviceType[]> => {
      try {
        const microphones = await AgoraRTC.getMicrophones();
        return filterAndSortMicrophones(microphones);
      } catch (error) {
        await handleMicrophoneDisconnection(error);
        return [];
      }
    };
    // Initialize camera list and set current camera
    const initializeMicrophoneSelection = async (microphones: DeviceType[]) => {
      listMics.value = microphones;
      listMicIds.value = microphones.map((mic) => mic.deviceId);

      const activeMicrophone = microphones.find((cam) => cam.deviceId === activeMicrophoneId.value);
      if (activeMicrophone) {
        currentMic.value = activeMicrophone;
        activeMicrophoneId.value = activeMicrophone.deviceId;
      } else if (microphones.length > 0) {
        // The first microphone in the list is the system default microphone
        currentMic.value = microphones[0];
        activeMicrophoneId.value = microphones[0]?.deviceId;
      } else {
        currentMic.value = undefined;
        activeMicrophoneId.value = undefined;
      }
    };
    const updateMicrophoneList = async () => {
      if (!localTracks.value.audioTrack) {
        return await setupMicrophone();
      }

      const availableMicrophones = await fetchAvailableMicrophones();
      await initializeMicrophoneSelection(availableMicrophones);

      if (activeMicrophoneId.value) {
        try {
          await localTracks.value.audioTrack.setDevice(activeMicrophoneId.value);
          microphoneError.value = createDefaultMediaError(); // Clear error when a valid camera is set
        } catch (error) {
          await handleMicrophoneDisconnection(error);
        }
      } else {
        await handleMicrophoneDisconnection(new Error(MicrophoneCustomErrorCode.MICROPHONE_NOT_FOUND));
      }

      if (props.isMidSession) {
        await store.dispatch("setMicrophoneDeviceId", activeMicrophoneId.value);
        await store.dispatch("calling/callingUpdateDevice", { deviceType: CallingDeviceType.MICROPHONE });
      }
    };

    // Change camera based on user selection
    const onMicrophoneChange = async (newMicId: string) => {
      try {
        const selectedMic = listMics.value.find((mic) => mic.deviceId === newMicId);
        if (!selectedMic) throw new Error("Microphone not found");
        activeMicrophoneId.value = newMicId;
        currentMic.value = selectedMic;
        if (!localTracks.value.audioTrack) {
          await createAndPlayNewAudioTrack(newMicId, false);
        }
        if (localTracks.value.audioTrack) {
          await localTracks.value.audioTrack.setDevice(newMicId);
        }
        microphoneError.value = createDefaultMediaError(); // Clear error when a valid camera is selected
      } catch (err) {
        await handleMicrophoneDisconnection(err);
      }
    };

    // Handle microphone disconnection or errors
    const handleMicrophoneDisconnection = async (error: any) => {
      switchMicrophoneStatus(false);
      await toggleAudioWave(false);
      microphoneError.value = mappingAgoraMicrophoneError(error);
    };

    // Update camera status (on/off)
    const switchMicrophoneStatus = (enabled: boolean) => {
      isMicrophoneEnabled.value = enabled;
    };

    const cancelVolumeAnimation = () => {
      cancelAnimationFrame(volumeAnimation.value);
      volumeAnimation.value = null;
      volumeByPercent.value = 0;
    };
    const setVolumeWave = () => {
      if (!localTracks.value?.audioTrack) return;
      volumeAnimation.value = window.requestAnimationFrame(setVolumeWave);
      volumeByPercent.value = localTracks.value.audioTrack.getVolumeLevel() * 100;
    };
    // Utility function to handle video track playback
    const toggleAudioWave = async (isOpen = true) => {
      if (isOpen) {
        volumeAnimation.value = window.requestAnimationFrame(setVolumeWave);
      } else {
        cancelVolumeAnimation();
      }
    };

    // Watch micro status toggle
    watch(isMicrophoneEnabled, async (enabled) => {
      emit("onOpenMicChange", enabled);
      emit("onMicrophoneStatusChange", enabled);
      await toggleAudioWave(enabled);
    });

    watch(activeMicrophoneId, async (microphoneId) => {
      emit("onCameraDeviceChange", microphoneId);
    });

    const createMicrophoneTrack = async (microphoneIds: string[]): Promise<IMicrophoneAudioTrack | null> => {
      let firstError: any = null; // Biến để lưu lỗi đầu tiên

      for (const microphoneId of microphoneIds) {
        try {
          const audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
            microphoneId,
          });
          Logger.log(`Successfully created audio track with microphoneId: ${microphoneId}`);
          activeMicrophoneId.value = microphoneId;
          microphoneError.value = createDefaultMediaError(); // Clear error when a valid microphone is found
          return audioTrack; // Trả về track nếu thành công
        } catch (error) {
          Logger.error(`Failed to create audio track for microphoneId: ${microphoneId}`, error);
          if (!firstError) {
            firstError = error; // Lưu lỗi đầu tiên nếu chưa có lỗi nào được lưu
          }
        }
      }

      if (firstError) {
        await handleMicrophoneDisconnection(firstError); // Gọi handleMicrophoneDisconnection với lỗi đầu tiên
      }

      return null; // Trả về null nếu không tạo được track
    };

    // When user try to open camera but it's not available, show a modal to inform user
    const onAttemptOpenErrorMicrophone = async () => {
      await dispatch(vuexName(VuexNames.MODAL.DISPATCHES.INFORM_DEVICE_WARNING), {
        code: microphoneError.value.code,
        isCamera: false,
        callback: handleRetryMicrophone,
      });
    };

    const handleRetryMicrophone = async () => {
      try {
        commit("modal/setIsSubmitting", true);
        const faultyMicrophoneId = activeMicrophoneId.value; // ID of the camera with the error
        if (!faultyMicrophoneId) {
          console.warn("No faulty microphone ID available.");
          return;
        }

        if (localTracks.value.audioTrack) {
          await retryExistingAudioTrack(faultyMicrophoneId);
        } else {
          await createAndPlayNewAudioTrack(faultyMicrophoneId);
        }

        // Clear any existing error after successful retry
        commit("modal/close");
        cameraError.value = createDefaultMediaError();
      } catch (error) {
        console.error("Retry failed:", error);
      } finally {
        commit("modal/setIsSubmitting", false);
      }
    };

    // Helper to retry with an existing video track
    const retryExistingAudioTrack = async (faultyCameraId: string) => {
      const audioTrack = localTracks.value.audioTrack;
      if (audioTrack?.getTrackId() !== faultyCameraId) {
        await audioTrack?.setDevice(faultyCameraId);
      }
      switchMicrophoneStatus(true);
      await toggleAudioWave(true);
    };

    // Helper to create and play a new video track
    const createAndPlayNewAudioTrack = async (microphoneId: string, isPlay = true) => {
      const newTrack = await createMicrophoneTrack([microphoneId]);
      if (newTrack) {
        localTracks.value.audioTrack = newTrack;
        if (isPlay) {
          switchMicrophoneStatus(true);
        }
      } else {
        throw new Error("Failed to create a new audio track for the faulty microphone.");
      }
    };

    //#endregion

    //#region Speaker
    const setupSpeaker = async () => {
      try {
        AgoraRTC.onPlaybackDeviceChanged = updateSpeakerList;
        const availableSpeakers = await fetchAvailableSpeakers();
        if (availableSpeakers.length) {
          await initializeSpeakerSelection(availableSpeakers);
          const preferredSpeakerId = getPreferredSpeakerId(availableSpeakers);
          if (preferredSpeakerId) {
            await selectPreferredSpeaker(preferredSpeakerId);
          }
        }
      } catch (error) {
        logErrorDetails(error);
      } finally {
        isSpeakerInitialized.value = true;
      }
    };

    const getPreferredSpeakerId = (availableSpeakers: DeviceType[]) => {
      const persistedId: string = getters["speakerDeviceId"];
      const preferredSpeaker = availableSpeakers.find((speaker) => speaker.deviceId === persistedId);
      if (preferredSpeaker) {
        return preferredSpeaker.deviceId;
      }
      return null;
    };

    // Check if the preferred camera ID archived in the local storage is still available
    const selectPreferredSpeaker = async (preferredSpeakerId: string) => {
      try {
        activeSpeakerId.value = preferredSpeakerId;
      } catch (e) {
        handleSpeakerDisconnection(e);
      }
    };

    const toggleSpeaker = async () => {
      audio.value = document.getElementById("audio");
      isPlaySpeaker.value = !isPlaySpeaker.value;
      if (isPlaySpeaker.value && audio.value) {
        if (listSpeakers.value.length && currentSpeaker.value?.deviceId) {
          await audio.value.setSinkId(currentSpeaker.value?.deviceId);
        }
        audio.value.play();
      } else {
        audio.value.pause();
        audio.value.currentTime = 0;
      }
      isPlayingSound.value = !audio.value.paused;
    };

    const onSoundPlay = () => {
      isPlayingSound.value = true;
      isPlaySpeaker.value = true;
    };

    const onSoundPause = () => {
      isPlayingSound.value = false;
      isPlaySpeaker.value = false;
    };

    const onSpeakerChange = async (newSpeakerId: string) => {
      audio.value = document.getElementById("audio");
      const selectedSpeaker = listSpeakers.value.find((mic) => mic.deviceId === newSpeakerId);
      if (!selectedSpeaker) throw new Error("Speakers not found");
      currentSpeaker.value = selectedSpeaker;
      activeSpeakerId.value = newSpeakerId;
      if (audio.value) {
        await audio.value.setSinkId(currentSpeaker.value?.deviceId);
        audio.value.pause();
        audio.value.currentTime = 0;
        audio.value.play();
      } else {
        audio.value.pause();
        audio.value.currentTime = 0;
      }
      isPlayingSound.value = !audio.value.paused;
      isPlaySpeaker.value = !audio.value.paused;
    };

    // Handle speaker disconnection or errors
    const handleSpeakerDisconnection = (error: any) => {
      onSoundPause();
      speakerError.value = mappingAgoraMicrophoneError(error);
    };

    const updateSpeakerList = async () => {
      const availableSpeakers = await fetchAvailableSpeakers();
      await initializeSpeakerSelection(availableSpeakers);

      if (props.isMidSession) {
        await store.dispatch("setSpeakerDeviceId", activeSpeakerId.value);
        await store.dispatch("calling/callingUpdateDevice", { deviceType: CallingDeviceType.SPEAKER });
      }
    };

    // Initialize speaker list and set current camera
    const initializeSpeakerSelection = async (speakers: DeviceType[]) => {
      listSpeakers.value = speakers;
      listSpeakerIds.value = speakers.map((speaker) => speaker.deviceId);

      const activeSpeaker = speakers.find((speaker) => speaker.deviceId === activeSpeakerId.value);
      if (activeSpeaker) {
        currentSpeaker.value = activeSpeaker;
        activeSpeakerId.value = activeSpeaker.deviceId;
      } else if (speakers.length > 0) {
        currentSpeaker.value = speakers[0];
        activeSpeakerId.value = speakers[0]?.deviceId;
      } else {
        currentSpeaker.value = undefined;
        activeSpeakerId.value = undefined;
      }
    };

    // Fetch available speakers
    const fetchAvailableSpeakers = async (): Promise<DeviceType[]> => {
      try {
        const speakers = await AgoraRTC.getPlaybackDevices();
        return filterAndSortSpeakers(speakers);
      } catch (error) {
        speakerError.value = mappingAgoraMicrophoneError(error);
        return [];
      }
    };

    watch(activeSpeakerId, async (speakerId) => {
      emit("onSpeakerDeviceChange", speakerId);
    });
    //#endregion

    //#region Camera Logic
    const createCameraTrack = async (cameraIds: string[]): Promise<ICameraVideoTrack | null> => {
      let firstError: any = null;

      for (const cameraId of cameraIds) {
        try {
          const videoTrack = await AgoraRTC.createCameraVideoTrack({
            cameraId,
            encoderConfig: { width: 1280, height: 720 },
          });
          Logger.log(`Successfully created video track with cameraId: ${cameraId}`);
          activeCameraId.value = cameraId;
          cameraError.value = createDefaultMediaError(); // Clear error when a valid camera is found
          return videoTrack; // Trả về track nếu thành công
        } catch (error) {
          Logger.error(`Failed to create video track for cameraId: ${cameraId}`, error);
          if (!firstError) {
            firstError = error; // Lưu lỗi đầu tiên nếu chưa có lỗi nào được lưu
          }
        }
      }

      if (firstError) {
        await handleCameraDisconnection(firstError); // Gọi handleCameraDisconnection với lỗi đầu tiên
      }

      return null; // Trả về null nếu không tạo được track
    };

    // Initialize camera list and set current camera
    const initializeCameraSelection = async (cameras: DeviceType[]) => {
      listCams.value = cameras;
      listCamIds.value = cameras.map((cam) => cam.deviceId);

      const activeCamera = cameras.find((cam) => cam.deviceId === activeCameraId.value);
      if (activeCamera) {
        currentCam.value = activeCamera;
        activeCameraId.value = activeCamera.deviceId;
      } else if (cameras.length > 0) {
        currentCam.value = cameras[0];
        activeCameraId.value = cameras[0]?.deviceId;
      }
    };

    // Change camera based on user selection
    const onCameraChange = async (newCamId: string) => {
      try {
        const selectedCam = listCams.value.find((cam) => cam.deviceId === newCamId);
        if (!selectedCam) throw new Error("Camera not found");
        activeCameraId.value = newCamId;
        currentCam.value = selectedCam;
        if (!localTracks.value.videoTrack) {
          await createAndPlayNewVideoTrack(newCamId, false);
        }
        if (localTracks.value.videoTrack) {
          await localTracks.value.videoTrack.setDevice(newCamId);
        }
        cameraError.value = createDefaultMediaError(); // Clear error when a valid camera is selected
      } catch (err) {
        await handleCameraDisconnection(err);
      }
    };

    // Handle camera disconnection or errors
    const handleCameraDisconnection = async (error: any) => {
      switchCameraStatus(false);
      await toggleVideoPlayback(false);
      cameraError.value = mappingAgoraCameraError(error);
    };

    // Fetch available cameras
    const fetchAvailableCameras = async (): Promise<DeviceType[]> => {
      try {
        return await AgoraRTC.getCameras();
      } catch (error) {
        cameraError.value = mappingAgoraCameraError(error);
        return [];
      }
    };

    // Update camera status (on/off)
    const switchCameraStatus = (enabled: boolean) => {
      isCameraEnabled.value = enabled;
    };

    // Setup camera on initial load
    const setupCamera = async () => {
      try {
        AgoraRTC.onCameraChanged = handleHotPluggingCamera;
        const availableCameras = await fetchAvailableCameras();
        if (availableCameras.length) {
          localTracks.value.videoTrack = await createCameraTrack(availableCameras.map((cam) => cam.deviceId));
          await initializeCameraSelection(availableCameras);
          const preferredCameraId = getPreferredCameraId(availableCameras);
          if (preferredCameraId) {
            await selectPreferredCamera(preferredCameraId);
          } else {
            switchCameraStatus(initialCameraStatus);
          }
        } else {
          if (!cameraError.value.isError) {
            throw new Error(CameraCustomErrorCode.CAMERA_NOT_FOUND);
          }
        }
      } catch (error) {
        handleCameraDisconnection(error);
      } finally {
        isCameraInitialized.value = true;
      }
    };

    // Check if the preferred camera ID archived in the local storage is still available
    const selectPreferredCamera = async (preferredCameraId: string) => {
      try {
        activeCameraId.value = preferredCameraId;
        if (localTracks.value.videoTrack) {
          await localTracks.value.videoTrack.setDevice(preferredCameraId);
          switchCameraStatus(initialCameraStatus);
          cameraError.value = createDefaultMediaError(); // Clear error when a valid camera is set
        }
      } catch (e) {
        await handleCameraDisconnection(e);
      }
    };

    const getPreferredCameraId = (availableCameras: DeviceType[]) => {
      const persistedId: string = getters["cameraDeviceId"];
      const preferredCamera = availableCameras.find((camera) => camera.deviceId === persistedId);
      if (preferredCamera) {
        return preferredCamera.deviceId;
      }
      return null;
    };

    // Handle camera hot-plugging
    const handleHotPluggingCamera = async () => {
      await updateCameraList();
    };

    // Update camera list and handle hot-plugging
    const updateCameraList = async () => {
      if (!localTracks.value.videoTrack) {
        return await setupCamera();
      }
      const oldActiveCameraId = activeCameraId.value;
      const availableCameras = await fetchAvailableCameras();
      await initializeCameraSelection(availableCameras);

      if (activeCameraId.value) {
        try {
          if (oldActiveCameraId !== activeCameraId.value) {
            await localTracks.value.videoTrack.setDevice(activeCameraId.value);
          }
          cameraError.value = createDefaultMediaError();
        } catch (error) {
          await handleCameraDisconnection(error); // Set error if switching device fails
        }
      } else {
        await handleCameraDisconnection(new Error(CameraCustomErrorCode.CAMERA_NOT_FOUND));
      }

      if (props.isMidSession) {
        const isCameraChanged = activeCameraId.value !== getters["cameraDeviceId"];
        if (isCameraChanged) {
          await store.dispatch("setCameraDeviceId", activeCameraId.value);
          const isOpenCameraInClass = deviceStatusStorageHandler.get()?.isCameraEnabled ?? true;
          await store.dispatch("calling/callingUpdateDevice", { deviceType: CallingDeviceType.CAMERA, isTurnOff: !isOpenCameraInClass });
        }
      }
    };

    // Utility function to handle video track playback
    const toggleVideoPlayback = async (isOpen = true) => {
      const videoTrack = localTracks.value?.videoTrack;
      if (!playerRef.value || !videoTrack) return;

      try {
        isOpen ? videoTrack.play(videoElementId, { mirror: false }) : videoTrack.stop();
      } catch (error) {
        cameraError.value = mappingAgoraCameraError(error);
      }
    };

    // Watch camera status toggle
    watch(isCameraEnabled, async (enabled) => {
      emit("onOpenCamChange", enabled);
      emit("onCameraStatusChange", enabled);
      await toggleVideoPlayback(enabled);
    });

    watch(activeCameraId, async (cameraId) => {
      emit("onCameraDeviceChange", cameraId);
    });

    // When user try to open camera but it's not available, show a modal to inform user
    const onAttemptOpenErrorCamera = async () => {
      await dispatch(vuexName(VuexNames.MODAL.DISPATCHES.INFORM_DEVICE_WARNING), {
        code: cameraError.value.code,
        isCamera: true,
        callback: handleRetryCamera,
      });
    };

    const handleRetryCamera = async () => {
      try {
        commit("modal/setIsSubmitting", true);
        const faultyCameraId = activeCameraId.value; // ID of the camera with the error
        if (!faultyCameraId) {
          console.warn("No faulty camera ID available.");
          return;
        }

        if (localTracks.value.videoTrack) {
          await retryExistingVideoTrack(faultyCameraId);
        } else {
          await createAndPlayNewVideoTrack(faultyCameraId);
        }

        // Clear any existing error after successful retry
        commit("modal/close");
        cameraError.value = createDefaultMediaError();
      } catch (error) {
        console.error("Retry failed:", error);
      } finally {
        commit("modal/setIsSubmitting", false);
      }
    };

    // Helper to retry with an existing video track
    const retryExistingVideoTrack = async (faultyCameraId: string) => {
      const videoTrack = localTracks.value.videoTrack;
      if (videoTrack?.getTrackId() !== faultyCameraId) {
        await videoTrack?.setDevice(faultyCameraId);
      }
      switchCameraStatus(true);
      await toggleVideoPlayback(true);
    };

    // Helper to create and play a new video track
    const createAndPlayNewVideoTrack = async (cameraId: string, isPlay = true) => {
      const newTrack = await createCameraTrack([cameraId]);
      if (newTrack) {
        localTracks.value.videoTrack = newTrack;
        if (isPlay) {
          switchCameraStatus(true);
        }
      } else {
        throw new Error("Failed to create a new video track for the faulty camera.");
      }
    };
    //#endregion

    const showCameraOptions = computed(() => !props.isMidSession || (props.isMidSession && isDesktopBrowser));

    const isCurrentCamError = computed(() => cameraError.value.isError);

    watch([cameraError, microphoneError, speakerError], ([cameraError, microphoneError, speakerError]) => {
      const isError = cameraError.isError || microphoneError.isError || speakerError.isError;
      emit("onDeviceError", isError);
    });

    onMounted(async () => {
      if (showCameraOptions.value) {
        await Promise.allSettled([setupCamera(), setupSpeaker(), setupMicrophone()]);
      } else {
        await Promise.allSettled([setupSpeaker(), setupMicrophone()]);
      }
    });

    onUnmounted(async () => {
      if (audio.value) {
        audio.value.src = "";
      }
      AgoraRTC.onMicrophoneChanged = undefined;
      AgoraRTC.onCameraChanged = undefined;
      AgoraRTC.onPlaybackDeviceChanged = undefined;
      isConfigTrackingDone.value = false;
      if (audio.value && !audio.value.paused) {
        audio.value.pause();
        audio.value.currentTime = 0;
        isPlayingSound.value = false;
      }
      if (volumeAnimation.value) {
        cancelVolumeAnimation();
      }
      try {
        if (isMicrophoneEnabled.value) {
          await localTracks.value?.audioTrack?.setEnabled(false);
        }
        localTracks.value?.audioTrack?.close();
      } catch (error) {
        Logger.error(error);
      }
      try {
        if (isCameraEnabled.value) {
          await localTracks.value?.videoTrack?.setEnabled(false);
        }
        localTracks.value?.videoTrack?.close();
      } catch (error) {
        Logger.error(error);
      }
      localTracks.value = {
        videoTrack: null,
        audioTrack: null,
      };
      currentMic.value = undefined;
      currentCam.value = undefined;
      isOpenMic.value = props.isMicEnabled;
      isCameraEnabled.value = props.isCamEnabled;
      currentSpeaker.value = undefined;
      isPlaySpeaker.value = false;
      isPlayingSound.value = false;
      cameraError.value = createDefaultMediaError();
      microphoneError.value = createDefaultMediaError();
      speakerError.value = createDefaultMediaError();
      await dispatch("calling/reRegisterHotPluggingDevicesEventListener");
    });

    watch(areAllMediaDevicesInitialized, async (initialized) => {
      if (!initialized) return;
      emit("onDevicesInitialized");
    });

    const MsgCameraErrorInfo = computed(() => fmtMsg(DeviceTesterLocale.CameraErrorInfo));
    const MsgMicroErrorInfo = computed(() => fmtMsg(DeviceTesterLocale.MicroErrorInfo));
    const MsgSpeakerErrorInfo = computed(() => fmtMsg(DeviceTesterLocale.SpeakerErrorInfo));
    const CheckMic = computed(() => fmtMsg(DeviceTesterLocale.CheckMic));
    const SelectDevice = computed(() => fmtMsg(DeviceTesterLocale.SelectDevice));
    const SystemDefaultSpeakerDevice = computed(() => fmtMsg(DeviceTesterLocale.SystemDefaultSpeakerDevice));
    const CheckCam = computed(() => fmtMsg(DeviceTesterLocale.CheckCam));
    const CheckSpeaker = computed(() => fmtMsg(DeviceTesterLocale.CheckSpeaker));
    const CamOff = computed(() => fmtMsg(DeviceTesterLocale.CamOff));
    const warningMsgSpeaker = computed(() => fmtMsg(DeviceTesterLocale.MessageWarningSpeaker));
    const warningMsgMicrophone = computed(() => fmtMsg(DeviceTesterLocale.MessageWarningMic));
    const warningMsgCamera = computed(() => fmtMsg(DeviceTesterLocale.MessageWarningCamera));
    const RemoteSetUpText = computed(() => props.title || fmtMsg(ClassSetUp.RemoteClassSetUp));
    const MsgDefaultDevice = computed(() => fmtMsg(DeviceTesterLocale.Recommended));
    return {
      MsgCameraErrorInfo,
      MsgMicroErrorInfo,
      MsgSpeakerErrorInfo,
      RemoteSetUpText,
      warningMsgSpeaker,
      warningMsgMicrophone,
      warningMsgCamera,
      havePermissionCamera,
      havePermissionMicrophone,
      havePermissionSpeaker,
      CheckMic,
      SelectDevice,
      SystemDefaultSpeakerDevice,
      CheckCam,
      CamOff,
      isOpenMic,
      isCameraEnabled,
      playerRef,
      volumeByPercent,
      listMics,
      listCams,
      listCamIds,
      listMicIds,
      currentCam,
      currentMicLabel,
      onMicrophoneChange,
      onCameraChange,
      videoElementId,
      agoraCamError,
      agoraMicError,
      listSpeakers,
      listSpeakerIds,
      currentSpeakerLabel,
      CheckSpeaker,
      isPlayingSound,
      onSpeakerChange,
      speakerIcon,
      toggleSpeaker,
      showCameraOptions,
      isCurrentCamError,
      PlayCircleIcon,
      PauseCircleIcon,
      onSoundPlay,
      onSoundPause,
      isCameraInitialized,
      isMicrophoneInitialized,
      cameraError,
      onAttemptOpenErrorCamera,
      activeCameraId,
      activeMicrophoneId,
      isMicrophoneEnabled,
      MsgDefaultDevice,
      microphoneError,
      onAttemptOpenErrorMicrophone,
      speakerError,
      activeSpeakerId,
      areAllMediaDevicesInitialized,
    };
  },
});
