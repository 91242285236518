<template>
  <div v-if="helperInfo?.id" class="relative text-slate-600 rounded-xl w-full overflow-hidden group" :class="{ shadow: !isVideoShown }">
    <div class="aspect-w-16 aspect-h-9 bg-white">
      <figure>
        <div class="absolute inset-0 flex justify-center items-center">
          <svg
            v-if="isVideoShown || !isJoinedSession"
            class="animate-spin h-6 w-6 text-slate-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <ConnectAvatar v-else :src="helperAvatar" :alt="helperInfo?.name" />
        </div>
        <div :class="['w-full h-full object-cover', { hidden: !isVideoShown }]" :id="helperId" />
      </figure>
    </div>
    <div
      :class="{ 'opacity-0': isVideoShown }"
      class="absolute top-2 left-1/2 -translate-x-1/2 flex space-x-1.5 group-hover:opacity-100 transition-opacity"
    >
      <template v-if="isJoinedSession">
        <template v-if="isMeHelper">
          <div class="relative">
            <DeviceErrorTooltip
              class="absolute right-[-30%] top-[-30%]"
              v-if="cameraError.isError"
              :message="cameraError.customMessage"
              :altText="'Camera error info'"
            />
            <button
              @click="cameraError.isError ? openDeviceWarningModal(true) : onHelperToggleCamera()"
              :disabled="isCameraToggling"
              class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer disabled:cursor-wait"
            >
              <LoadingOutlined class="animate-spin" v-if="isCameraToggling" />
              <component v-else :is="isVideoPublished ? VideoCameraIcon : VideoCameraSlashIcon" class="h-4 w-4" />
            </button>
          </div>
          <div class="relative">
            <DeviceErrorTooltip
              class="absolute right-[-30%] top-[-30%]"
              v-if="microphoneError.isError"
              :message="microphoneError.customMessage"
              :altText="'Microphone error info'"
            />
            <button
              @click="microphoneError.isError ? openDeviceWarningModal(false) : onHelperToggleMicro()"
              :disabled="isMicrophoneToggling"
              class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer disabled:cursor-wait"
            >
              <LoadingOutlined class="animate-spin" v-if="isMicrophoneToggling" />
              <MicrophoneIcon v-else icon-class="h-4 w-4" :enabled="isAudioPublished" />
            </button>
          </div>

          <div
            @click="openDeviceSettingsModal"
            class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer"
          >
            <Cog6ToothIcon class="h-4 w-4" />
          </div>
          <div
            @click="onClickMinimized"
            class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer"
          >
            <ArrowsPointingInIcon class="h-4 w-4" />
          </div>
          <DeviceSettingsModal
            v-model:visible="isDeviceSettingsModalShown"
            :isMicEnabled="isAudioPublished"
            :isCamEnabled="isVideoPublished"
            :onUpdateCamOpen="updateCamOpen"
            :onUpdateMicOpen="updateMicOpen"
          />
        </template>
        <template v-else>
          <div
            @click="toggleHelperVideo(!isTeacherAllowStudentSeeHelperVideo)"
            :class="{ 'pointer-events-none': toggleHelperVideoLoading, 'cursor-pointer': !toggleHelperVideoLoading }"
            class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors"
          >
            <component :is="isTeacherAllowStudentSeeHelperVideo ? EyeIcon : EyeSlashIcon" class="h-4 w-4" />
          </div>
          <div
            @click="onClickMinimized"
            class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer"
          >
            <ArrowsPointingInIcon class="h-4 w-4" />
          </div>
          <div
            class="h-7 w-7 bg-red-800/80 text-white flex items-center justify-center rounded-md hover:bg-red-800 hover:shadow transition-colors cursor-pointer"
            @click="onRemoveHelper"
          >
            <XMarkIcon class="h-4 w-4" />
          </div>
        </template>
      </template>
      <template v-else>
        <div
          @click="onClickMinimized"
          class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer"
        >
          <ArrowsPointingInIcon class="h-4 w-4" />
        </div>
      </template>
    </div>
    <NameTag :name="helperInfo?.name" :is-muted="!isAudioPublished" class="absolute bottom-2 left-3" />
  </div>
</template>

<script lang="ts" src="./helper-card.ts"></script>
