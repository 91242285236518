<template>
  <div>
    <ul v-if="code === CameraCustomErrorCode.CAMERA_DEVICE_IN_USE" class="list-disc pl-5 mb-4">
      <li>{{ MsgCameraInUseDetailed1 }}</li>
      <li>{{ MsgCameraInUseDetailed2 }}</li>
      <li>{{ MsgDeviceReopenBrowser }}</li>
    </ul>
    <ul v-if="code === MicrophoneCustomErrorCode.MICROPHONE_DEVICE_IN_USE" class="list-disc pl-5 mb-4">
      <li>{{ MsgMicrophoneInUseDetailed1 }}</li>
      <li>{{ MsgMicrophoneInUseDetailed2 }}</li>
      <li>{{ MsgDeviceReopenBrowser }}</li>
    </ul>

    <div v-if="code === CameraCustomErrorCode.CAMERA_PERMISSION_DENIED" class="flex">
      <img src="@/assets/images/permission_denied.webp" alt="" class="w-1/3" />
      <ul class="list-decimal pl-5 mb-4">
        <li>{{ DevicePressOnIcon }}</li>
        <li>{{ MsgCameraPermissionDeniedDetailed2 }}</li>
        <li>{{ MsgDeviceReloadPage }}</li>
      </ul>
    </div>
    <div v-if="code === MicrophoneCustomErrorCode.MICROPHONE_PERMISSION_DENIED" class="flex">
      <img src="@/assets/images/permission_denied.webp" alt="" class="w-1/3" />
      <ul class="list-decimal pl-5 mb-4">
        <li>{{ DevicePressOnIcon }}</li>
        <li>{{ MsgMicrophonePermissionDeniedDetailed2 }}</li>
        <li>{{ MsgDeviceReloadPage }}</li>
      </ul>
    </div>

    <div v-if="code === MicrophoneCustomErrorCode.MICROPHONE_ENABLE_NOISE_CANCELLING_FAILED" class="flex">
      {{ MsgMicrophoneEnableNoiseCancellingFailedDetailed }}
    </div>
    <div v-if="code === MicrophoneCustomErrorCode.MICROPHONE_NOT_FOUND" class="flex">
      {{ MsgMicrophoneNotFoundDetailed }}
    </div>
    <div v-if="code === CameraCustomErrorCode.CAMERA_NOT_FOUND" class="flex">
      {{ MsgCameraNotFoundDetailed }}
    </div>
  </div>
</template>
<script lang="ts" src="./device-warning-content.ts"></script>
