import { AnnotationModel } from "@/models";
import { FabricObject } from "@/ws";
import { MutationTree } from "vuex";
import { AnnotationState, LastFabricUpdated, Pointer, UserShape, initAnnotationState } from "./state";
import { cloneDeep } from "lodash";
import { defineVuexName, VuexNames } from "@/store/utils";

export interface AnnotationMutationInterface<S> {
  setInfo(s: S, p: AnnotationModel): void;
  setPointer(s: S, pointer: Pointer): void;
  setMode(s: S, p: { mode: number }): void;
  addShape(s: S, p: string): void;
  setTeacherBrushes(s: S, p: Array<string>): void;
  setOneTeacherDrawsStrokes(s: S, p: string): void;
  setClearBrush(s: S, p: {}): void;
  setTeacherAddShape(s: S, p: { teacherShapes: Array<UserShape> }): void;
  setOneTeacherAddShape(s: S, p: { teacherShapes: Array<UserShape> }): void;
  setOneTeacherStrokes(s: S, p: Array<string>): void;
  setClearOneTeacherDrawsStrokes(s: S, p: {}): void;
  setClearOneTeacherAddShape(s: S, p: {}): void;
}
export interface IEditLine {
  objectId: string;
  metadata: string;
}
export interface AnnotationMutation<S> extends MutationTree<S>, AnnotationMutationInterface<S> {}

const mutations: AnnotationMutation<AnnotationState> = {
  resetState(s: AnnotationState) {
    Object.assign(s, initAnnotationState());
  },
  setInfo(s: AnnotationState, p: AnnotationModel) {
    if (!p) return;
    s.pointer = p.pointer;
    s.mode = p.mode;
    if (p.drawing) {
      s.drawing = p.drawing;
      s.drawing.teacherShapes = p.drawing.shapes;
    }
  },
  setInfoOneMode(s: AnnotationState, p: AnnotationModel) {
    if (!p) return;
    s.pointer = p.pointer;
    s.mode = p.mode;
    if (p.oneOneDrawing) {
      s.oneToOne = p.oneOneDrawing;
      s.oneToOne.teacherShapes = p.oneOneDrawing.shapes;
    }
  },
  cloneAnnotationsToOneOne(s: AnnotationState) {
    s.oneToOne = cloneDeep(s.drawing);
    s.oneToOne.teacherShapes = s.drawing.teacherShapes;
  },
  setPointer(s: AnnotationState, p: Pointer) {
    s.pointer = p;
  },
  setMode(s: AnnotationState, p: { mode: number }) {
    s.mode = p.mode;
  },
  // ** Update Paths if teacher add new paths */
  addShape(s: AnnotationState, p: string) {
    if (p) {
      s.drawing.brushstrokes = [...s.drawing.brushstrokes, p];
    } else {
      s.drawing.brushstrokes = [];
    }
  },
  // ** Update Paths in 1:1 if teacher add new paths */
  setOneTeacherDrawsStrokes(s: AnnotationState, p: string) {
    if (p) {
      s.oneToOne.brushstrokes = [...s.oneToOne.brushstrokes, p];
    } else {
      s.oneToOne.brushstrokes = [];
    }
  },
  //** Set Paths */
  setTeacherBrushes(s: AnnotationState, p: Array<string>) {
    s.drawing.brushstrokes = p;
  },
  //** Set Paths in 1:1 */
  setOneTeacherStrokes(s: AnnotationState, p: Array<string>) {
    s.oneToOne.brushstrokes = p;
  },
  //** set circles, squares ... */
  setTeacherAddShape(s: AnnotationState, p: { teacherShapes: Array<UserShape> }) {
    s.drawing.teacherShapes = p.teacherShapes;
  },
  //** set circles, squares ... in 1:1 */
  setOneTeacherAddShape(s: AnnotationState, p: { teacherShapes: Array<UserShape> }) {
    s.oneToOne.teacherShapes = p.teacherShapes;
  },
  //** set texts */
  setFabricsInDrawing(s: AnnotationState, p: FabricObject[]) {
    s.drawing.fabrics = p;
  },
  //** set texts in 1:1 */
  setFabricsInOneMode(s: AnnotationState, p: FabricObject[]) {
    s.oneToOne.fabrics = p;
  },
  setClearOneTeacherDrawsStrokes(s: AnnotationState) {
    s.oneToOne.brushstrokes = [];
  },
  setClearBrush(s: AnnotationState) {
    s.drawing = {
      pencil: null,
      brushstrokes: [],
      studentShapes: [],
      teacherShapes: [],
      fabrics: [],
    };
    s.oneToOne = {
      pencil: null,
      brushstrokes: [],
      studentShapes: [],
      teacherShapes: [],
      fabrics: [],
    };
  },
  clearAnnotationState(s: AnnotationState) {
    s.drawing = {
      pencil: null,
      brushstrokes: [],
      studentShapes: [],
      teacherShapes: [],
      fabrics: [],
    };
    s.oneToOne = {
      pencil: null,
      brushstrokes: [],
      studentShapes: [],
      teacherShapes: [],
      fabrics: [],
    };
    s.drawings = [];
    s.pencilPath = [];
    s.laserPath.isDone = true;
    s.laserPath.lines = [];
  },
  setClearOneTeacherAddShape(s: AnnotationState) {
    s.oneToOne.teacherShapes = [];
  },
  setLastFabricUpdated(s: AnnotationState, p: LastFabricUpdated | null) {
    s.lastFabricUpdated = p;
  },
  setLastFabricIdDeleted(s: AnnotationState, p: string | null) {
    s.lastFabricIdDeleted = p;
  },
  //* Currently it is only used to save "text boxes", in the future it will be used to save all annotations */
  setDrawings(state: AnnotationState, drawings: FabricObject[]) {
    state.drawings = drawings;
  },
  setImgDimension(state: AnnotationState, p: { width?: number; height?: number }) {
    state.imgWidth = p.width;
    state.imgHeight = p.height;
  },
  setDrawPencil(state: AnnotationState, payload: any) {
    const index = state.pencilPath.findIndex((item) => item.id === payload.id);
    if (!state.pencilPath.length || index < 0) {
      if (state.pencilPath.length && !state.pencilPath[state.pencilPath.length - 1].isDone) {
        state.pencilPath[state.pencilPath.length - 1].isDone = true;
      }
      state.pencilPath.push({
        ...payload,
        points: [...payload.pointsSkipped, payload.points],
        strokeColor: payload.strokeColor,
        strokeWidth: payload.strokeWidth,
        isDone: payload.isDone,
        ratio: payload.ratio,
        lineIdRelated: payload.lineIdRelated,
      });
    } else if (index >= 0) {
      state.pencilPath[index] = {
        ...state.pencilPath[index],
        points: [...state.pencilPath[index].points, ...payload.pointsSkipped, payload.points],
        isDone: payload.isDone,
        objectId: payload.objectId,
      };
    }
  },
  clearPencilPath(state: AnnotationState) {
    state.pencilPath = [];
  },
  setDrawLaser(state: AnnotationState, payload: any) {
    if (payload.isDone) {
      state.laserPath.isDone = payload.isDone;
      state.laserPath.lines = [];
    } else {
      const index = state.laserPath.lines.findIndex((item) => item.id === payload.data.id);
      state.laserPath.isDone = payload.isDone;
      if (!state.laserPath.lines.length || index < 0) {
        state.laserPath.lines.push({
          ...payload.data,
          points: [...payload.data.pointsSkipped, payload.data.points],
        });
      } else if (index >= 0) {
        state.laserPath.lines[index] = {
          ...state.laserPath.lines[index],
          points: [...state.laserPath.lines[index].points, ...payload.data.pointsSkipped, payload.data.points],
        };
      }
    }
  },
  setFabricObjects(state: AnnotationState, payload: FabricObject) {
    const existingObject = state.drawing.fabrics.find((item) => item.fabricId === payload.fabricId);
    if (!state.drawing.fabrics.length || !existingObject) {
      state.drawing.fabrics.push(payload);
    } else {
      if (existingObject) {
        existingObject.fabricData = payload.fabricData;
      }
    }
  },
  setOneToOneFabricObjects(state: AnnotationState, payload: FabricObject) {
    const existingObject = state.oneToOne.fabrics.find((item) => item.fabricId === payload.fabricId);
    if (!state.oneToOne.fabrics.length || !existingObject) {
      state.oneToOne.fabrics.push(payload);
    } else {
      if (existingObject) {
        existingObject.fabricData = payload.fabricData;
      }
    }
  },
  removePolyline(state: AnnotationState, objectId: string) {
    state.pencilPath = state.pencilPath.filter((item) => item.objectId !== objectId);
  },
  removeLine(state: AnnotationState, objectId: string) {
    if (state.drawing.brushstrokes) {
      state.drawing.brushstrokes = state.drawing.brushstrokes.filter((item) => JSON.parse(item).objectId !== objectId);
    }
  },
  removeOneModeLine(state: AnnotationState, objectId: string) {
    if (state.oneToOne.brushstrokes) {
      state.oneToOne.brushstrokes = state.oneToOne.brushstrokes.filter((item) => JSON.parse(item).objectId !== objectId);
    }
  },
  editLine(state: AnnotationState, payload: IEditLine) {
    const { objectId, metadata } = payload;
    if (state.drawing.brushstrokes) {
      const lineIndex = state.drawing.brushstrokes.findIndex((item) => JSON.parse(item).objectId === objectId);
      if (lineIndex >= 0) {
        state.drawing.brushstrokes.splice(lineIndex, 1, metadata);
      }
    }
  },
  editOneModeLine(state: AnnotationState, payload: IEditLine) {
    const { objectId, metadata } = payload;
    if (state.oneToOne.brushstrokes) {
      const lineIndex = state.oneToOne.brushstrokes.findIndex((item) => JSON.parse(item).objectId === objectId);
      if (lineIndex >= 0) {
        state.oneToOne.brushstrokes.splice(lineIndex, 1, metadata);
      }
    }
  },
  [defineVuexName(VuexNames.ANNOTATION.COMMITS.REMOVE_SHAPE)](
    state: AnnotationState,
    { objectId, isOneToOne }: { objectId: string; isOneToOne: boolean },
  ) {
    const shapes = isOneToOne ? state.oneToOne.teacherShapes : state.drawing.teacherShapes;

    if (shapes) {
      const updatedShapes = shapes.map((shape) => {
        shape.brushstrokes = shape.brushstrokes.filter((stroke) => JSON.parse(stroke)?.objectId !== objectId);
        return shape;
      });

      if (isOneToOne) {
        state.oneToOne.teacherShapes = updatedShapes;
      } else {
        state.drawing.teacherShapes = updatedShapes;
      }
    }
  },
};

export default mutations;
