import { IndependentLocales, TeacherClassGallery, TeamLocales } from "@/locales/localeid";
import { StudentState } from "@/store/room/interface";
import { Tooltip, Switch } from "ant-design-vue";
import moment from "moment";
import { computed, defineComponent, ref, watch } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { useStore } from "vuex";
import StudentAll from "./student-all/student-all.vue";
import StudentControls from "./student-controls/student-controls.vue";
import { PencilIcon, ArrowLeftIcon } from "@heroicons/vue/24/solid";
import { UsersIcon } from "@heroicons/vue/24/solid";
import { useManipulateTeamState } from "@/hooks/use-team";
import { upperCaseFirstLetter } from "@/utils/utils";
import { vuexName, VuexNames } from "@/store/utils";

const TIMESTAMP_ONE_AND_ONE = "TIMESTAMP_ONE_AND_ONE";

export default defineComponent({
  components: {
    Tooltip,
    StudentAll,
    StudentControls,
    PencilIcon,
    ArrowLeftIcon,
    UsersIcon,
    Switch,
  },
  emits: ["inactive-object-canvas"],
  setup(_, { emit }) {
    const isIndependentModeEnabled = computed(() => getters[vuexName(VuexNames.CLASS_TEACHING.GETTERS.GET_IS_INDEPENDENT_MODE_ENABLED)]);
    const TeamsLocale = computed(() => fmtMsg(TeamLocales.Teams));
    const { getters, dispatch } = useStore();
    const { teams, isTeamMode, nonTeamStudents, toggleTeamMode, settingTeams } = useManipulateTeamState();
    const shownTeams = computed(() =>
      teams.value.filter((t) => t.students.length && t.students.some((s) => getters["calling/checkCallingHasUserById"](s.id))),
    );
    const nonTeamsShown = computed(
      () => nonTeamStudents.value.length && nonTeamStudents.value.some((s) => getters["calling/checkCallingHasUserById"](s.id)),
    );
    const oneAndOneStatus = computed(() => getters["teacherRoom/getStudentModeOneId"]);
    const students = computed<Array<StudentState>>(() => getters["teacherRoom/students"]);
    const returnText = computed(() => fmtMsg(TeacherClassGallery.Return));

    const isShowPreviewCanVas = computed(() => getters["lesson/isShowPreviewCanvas"]);
    const minute = ref(0);
    const second = ref(0);
    const timeCount = ref("");
    const isHelper = computed(() => getters["teacher/isHelper"]);
    let intervalId: NodeJS.Timeout | null = null;
    const oneToOneTimer = () => {
      if (second.value < 59) {
        second.value = second.value + 1;
      } else {
        minute.value = minute.value + 1;
        second.value = 0;
      }
      timeCount.value = `${minute.value < 10 ? "0" + minute.value : minute.value}:${second.value < 10 ? "0" + second.value : second.value}`;
    };

    watch(oneAndOneStatus, () => {
      if (!oneAndOneStatus.value) {
        if (intervalId !== null) {
          clearInterval(intervalId);
        }
        sessionStorage.removeItem(TIMESTAMP_ONE_AND_ONE);
        return;
      }
      intervalId = setInterval(oneToOneTimer, 1000);
      const savedTimestampOneAndOne = parseInt(sessionStorage.getItem(TIMESTAMP_ONE_AND_ONE) || Date.now().toString());
      sessionStorage.setItem(TIMESTAMP_ONE_AND_ONE, savedTimestampOneAndOne.toString());

      const savedMoment = moment(savedTimestampOneAndOne);
      minute.value = moment(Date.now()).diff(savedMoment, "minute");
      second.value = moment(Date.now()).diff(savedMoment, "second");
      timeCount.value = "";
    });

    const backToClass = async () => {
      emit("inactive-object-canvas");
      if (isShowPreviewCanVas.value) {
        await dispatch("lesson/setShowPreviewCanvas", false, {
          root: true,
        });
      }
      await dispatch("teacherRoom/sendOneAndOne", {
        status: false,
        id: null,
        isHelper: isHelper.value,
      });
    };

    const handleSettingTeams = async () => {
      // TODO: this line right below handle for GL-18587; but need refactor in the future
      emit("inactive-object-canvas");
      await settingTeams();
    };
    const canToggleTeamMode = computed(() => !(isIndependentModeEnabled.value && !isTeamMode.value));
    const handleToggleTeamMode = async () => {
      // TODO: this line right below handle for GL-18587; but need refactor in the future
      emit("inactive-object-canvas");
      const noTeamsYet = !teams.value.length;
      if (!isTeamMode.value && noTeamsYet) {
        await settingTeams(true);
      } else {
        await toggleTeamMode();
      }
    };

    const MsgEditTeam = computed(() => fmtMsg(TeamLocales.EditTeam));
    const MsgIndependentModeName = computed(() => fmtMsg(IndependentLocales.IndependentMode));
    const MsgPreventToggleTeamModeWarning = computed(() =>
      fmtMsg(TeamLocales.PreventToggleTeamModeWarning, { modeName: MsgIndependentModeName.value }),
    );
    return {
      MsgEditTeam,
      TeamsLocale,
      oneAndOneStatus,
      timeCount,
      backToClass,
      returnText,
      isTeamMode,
      teams,
      nonTeamStudents,
      upperCaseFirstLetter,
      students,
      settingTeams,
      handleToggleTeamMode,
      handleSettingTeams,
      canToggleTeamMode,
      MsgPreventToggleTeamModeWarning,
      shownTeams,
      nonTeamsShown,
    };
  },
});
