import { AgoraEventHandler } from "@/agora";
import { ErrorLocale, TeacherActionLocale, TeacherClassError } from "@/locales/localeid";
import { RoomModel, SendRealtimePenMessage, SendPointerMessage, TeachingMode } from "@/models";
import { GLError, GLErrorCode } from "@/models/error.model";
import { UpdateLessonAndUnitModel } from "@/models/update-lesson-and-unit.model";
import { UserModel } from "@/models/user.model";
import router from "@/router";
import {
  FetchSessionDtoResModel,
  HelperService,
  InfoService,
  OneToOneDto,
  RemoteTeachingService,
  TeacherUpdateAlternateState,
  TeacherUpdateMediaStateRequestModel,
  ToggleStudentMediaDevicesAsync,
  ToggleStudentPaletteAsync,
  transformOneToOneDataServeHelper,
  transformOneToOneDataServeTeacher,
} from "@/services";
import { GetIndependentStudentsResponse, IndependentService } from "@/services/independent";
import { store } from "@/store";
import { UserRole } from "@/store/app/state";
import { callingUtils } from "@/store/room/teacher/utils";
import { StudyMode } from "@/store/teaching/interfaces";
import { AGORA_MINIMUM_USER_VOLUME, MIN_ZOOM_RATIO } from "@/utils/constant";
import { Logger } from "@/utils/logger";
import { Paths } from "@/utils/paths";
import { getDeviceId, MediaDeviceTypes } from "@/utils/utils";
import { FabricObject } from "@/ws";
import { ConnectionDisconnectedReason, ConnectionState, IAgoraRTCRemoteUser, UID } from "agora-rtc-sdk-ng";
import { notification } from "ant-design-vue";
import { ErrorCode, fmtMsg } from "vue-glcommonui";
import { ActionTree } from "vuex";
import { VideoCallStatus, DefaultPayload, DeviceMediaPayload, InitClassRoomPayload, NetworkQualityPayload, UserIdPayload } from "../interface";
import { ParamsToJoinCurSessionInterface } from "./../interface";
import { useTeacherRoomWSHandler } from "./handler";
import { TeacherRoomState } from "./state";
import { CanvasObjectModel } from "@/hooks/use-send-websocket-msg";
import { serializeFabricElementWithCustomAttributes } from "@/utils/fabric-utils";
import { ExposureDetailType } from "@/views/teacher-class/components/lesson-plan/lesson-plan";
import { defineVuexName, vuexName, VuexNames } from "@/store/utils";
import { AntdNotificationType } from "@/utils/type";
import { showAPIErrorNotification, showNotification } from "@/utils/antd-utils";
import { MediaUserPublishStatus } from "@/store/calling/utils";
import { CallingUserStatus } from "@/store/calling/mutations";

const actions: ActionTree<TeacherRoomState, any> = {
  async endClass({ dispatch, state, commit }, payload: DefaultPayload) {
    if (state.info) {
      const { markAsComplete } = payload;
      await RemoteTeachingService.teacherEndClassRoom(state.info?.id, markAsComplete);
      commit("setIsSessionEnded", true);
    }
    await dispatch("resetClass");
    commit("teacher/setIgnoreHelperRequestJoinClass", false, { root: true });
  },
  /** Reset class's state in local */
  async resetClass({ commit }, endClass = true) {
    if (endClass) {
      commit("endClass");
    } else {
      commit("leaveClass");
    }
    commit("lesson/resetState", null, { root: true });
    commit("calling/resetState", null, { root: true });
    commit("classTeaching/endClass", null, { root: true });
  },
  async helperExitClass({ commit, state, dispatch }, byTeacherRemove: boolean) {
    if (state.info && !byTeacherRemove) {
      try {
        await HelperService.exitSession();
      } catch (error) {
        Logger.error(error);
      }
    }
    await dispatch("resetClass");
    commit("teacher/setIsHelper", false, { root: true });
  },
  setTeachingModeAsync({ state, commit }, mode: TeachingMode) {
    commit("setTeachingMode", mode);
    state.manager?.WSClient.sendRequestSetTeachingMode(mode);
  },
  setUser({ commit }, payload: UserModel) {
    commit("setUser", payload);
  },
  setError(store, payload: GLError | null) {
    store.commit("setError", payload);
  },
  async [defineVuexName(VuexNames.TEACHER_ROOM.DISPATCHES.MANAGE_REMOTE_USER_VIDEO_AND_AUDIO_SUBSCRIPTIONS)]({ state, rootGetters }) {
    const { manager, idOne: oneToOneStudentId, idOneWithAnotherTeacher: oneToOneWithAnotherTeacherStudentId, teacher, helper } = state;
    const meId: string | undefined = rootGetters["auth/userId"];

    if (!meId || !teacher || !manager?.agoraClient?.joined) return;

    const isHelperJoinClass = !!helper;
    const isMeHelper = isHelperJoinClass && meId === helper.id;

    let remoteVideoUserIds: string[] = rootGetters[vuexName(VuexNames.CALLING.GETTERS.GET_VIDEO_USER_IDS)].filter((id: string) => id !== meId);
    let remoteAudioUserIds: string[] = rootGetters[vuexName(VuexNames.CALLING.GETTERS.GET_AUDIO_USER_IDS)].filter((id: string) => id !== meId);

    // If the current user is a teacher, only subscribe to the helper's video if the helper is allowed to share video
    if (!isMeHelper && isHelperJoinClass && !helper.isVideoShownByTeacher) {
      remoteVideoUserIds = remoteVideoUserIds.filter((id: string) => id !== helper.id);
    }

    // If I'm in one-to-one mode with a student, only subscribe to the student's video and audio
    if (oneToOneStudentId) {
      if (isMeHelper) {
        remoteVideoUserIds = remoteVideoUserIds.filter((id: string) => id === oneToOneStudentId || id === teacher.id);
        const isMuteTeacher = rootGetters[vuexName(VuexNames.CLASS_TEACHING.GETTERS.GET_IS_MUTE_TEACHER_AUDIO_WHEN_ONE_TO_ONE_WITH_HELPER)];
        remoteAudioUserIds = remoteAudioUserIds.filter((id: string) => id === oneToOneStudentId || (id === teacher.id && !isMuteTeacher));
      } else {
        remoteVideoUserIds = remoteVideoUserIds.filter((id: string) => id === oneToOneStudentId);
        remoteAudioUserIds = remoteAudioUserIds.filter((id: string) => id === oneToOneStudentId);
      }
    }

    // If another teacher is in one-to-one mode with a student, remove both of them from the video and audio subscriptionss
    if (oneToOneWithAnotherTeacherStudentId) {
      if (isMeHelper) {
        remoteVideoUserIds = remoteVideoUserIds.filter((id: string) => id !== oneToOneWithAnotherTeacherStudentId && id !== teacher.id);
        remoteAudioUserIds = remoteAudioUserIds.filter((id: string) => id !== oneToOneWithAnotherTeacherStudentId && id !== teacher.id);
      } else if (isHelperJoinClass) {
        remoteVideoUserIds = remoteVideoUserIds.filter((id: string) => id !== oneToOneWithAnotherTeacherStudentId && id !== helper.id);
        remoteAudioUserIds = remoteAudioUserIds.filter((id: string) => id !== oneToOneWithAnotherTeacherStudentId && id !== helper.id);
      }
    }
    return manager.updateAudioAndVideoFeed([...new Set(remoteVideoUserIds)], [...new Set(remoteAudioUserIds)]);
  },
  async leaveRoom({ state, commit }, _payload: { leave: boolean }) {
    await state.manager?.close(_payload.leave);
    // reset module's state
    commit("leaveClass");
    commit("classTeaching/endClass", null, { root: true });
  },
  async joinWSRoom(store, rejoin = false) {
    if (!store.state.info || !store.state.manager) return;
    const { teacher } = store.state;
    const userId: string | undefined = store.rootGetters["auth/userId"];
    const isHelper = teacher && userId !== teacher?.id;
    const deviceId = getDeviceId();
    store.state.manager?.WSClient.sendRequestJoinRoom(deviceId, isHelper);
    if (rejoin) return;
    const eventHandler = useTeacherRoomWSHandler(store);
    store.state.manager?.registerEventHandler(eventHandler);
  },
  async joinRoom(store) {
    const { state, rootGetters } = store;
    const needConfirmToHandOver = rootGetters["getReplacedClassId"];
    if (needConfirmToHandOver) return;
    if (!state.info || !state.teacher || !state.manager) return;
    const {
      bandwidthUpdateBasedOnAgora,
      remoteUserJoined,
      remoteUserLeft,
      localUserConnectionChanged,
      handleUserPublishedAudio,
      handleUserPublishedVideo,
    } = callingUtils(store);
    const callingEventHandlers: AgoraEventHandler = {
      onLocalNetworkUpdate: (payload: NetworkQualityPayload) => {
        bandwidthUpdateBasedOnAgora(payload);
      },
      onUserJoined: ({ uid }: IAgoraRTCRemoteUser) => {
        const id = typeof uid === "string" ? uid : uid.toString();
        remoteUserJoined(id);
      },
      onUserLeft: async ({ uid }: IAgoraRTCRemoteUser, _: string) => {
        const id = typeof uid === "string" ? uid : uid.toString();
        await remoteUserLeft(id);
      },
      onConnectionStateChange: (curState: ConnectionState, prevState: ConnectionState, reason: ConnectionDisconnectedReason | undefined) => {
        localUserConnectionChanged(curState, prevState, reason);
      },
      onException: () => {
        //
      },
      onUserPublished: (userId: string, mediaType: "audio" | "video") => {
        if (mediaType === "audio") {
          handleUserPublishedAudio({ id: userId, status: MediaUserPublishStatus.PUBLISHED });
        } else if (mediaType === "video") {
          handleUserPublishedVideo({ id: userId, status: MediaUserPublishStatus.PUBLISHED });
        }
      },
      onUserUnPublished: (userId: string, mediaType: "audio" | "video") => {
        if (mediaType === "audio") {
          handleUserPublishedAudio({ id: userId, status: MediaUserPublishStatus.UNPUBLISHED });
        } else if (mediaType === "video") {
          handleUserPublishedVideo({ id: userId, status: MediaUserPublishStatus.UNPUBLISHED });
        }
      },
    };
    await state.manager?.join({
      classId: state.info.id,
      teacherId: state.user?.id,
      idOne: state.idOne,
      callingEventHandlers,
    });
    await store.dispatch("upToDateSessionAfterSignalRConnected");
  },
  async upToDateSessionAfterSignalRConnected({ rootState, commit, dispatch }) {
    let isCurrentUserInOneMode = false;
    const isHelper = rootState.teacher.isHelper;
    const sessionId = rootState.classTeaching.sessionId;
    /** Archive helper data */
    const setOneToOneWithHelperData = (oneToOneWithHelperDto: OneToOneDto) => {
      const { isIgnoreTeacherAudio } = oneToOneWithHelperDto;
      commit("classTeaching/setOneToOneWithHelperIgnoreTeacherVoice", isIgnoreTeacherAudio, { root: true });
    };
    let resp: FetchSessionDtoResModel | null = null;
    try {
      resp = await RemoteTeachingService.fetchSessionDtoById(sessionId);
    } catch (e) {
      Logger.error(e);
      return;
    }
    if (isHelper) {
      resp = transformOneToOneDataServeHelper(resp);
    } else {
      resp = transformOneToOneDataServeTeacher(resp);
    }
    const roomInfo = resp.data;
    /** If current lesson is "alternative" type, fetch media state to update the media's progress */
    commit("whiteboard/setShouldFetchMediaState", true, { root: true });
    commit("classTeaching/setMode", roomInfo.state.independentMode ? StudyMode.Independent : StudyMode.Normal, { root: true });
    commit("teams/setIsTeamMode", roomInfo.state.isTeamMode, { root: true });
    if (isHelper && roomInfo.helperOneAndOneDto?.studentId) {
      setOneToOneWithHelperData(roomInfo.helperOneAndOneDto);
    }
    if (roomInfo.helper) {
      commit("teacher/setIgnoreHelperRequestJoinClass", true, { root: true });
    }
    commit("setRoomInfo", { ...roomInfo, options: { isActionUpToDate: true, isHelper } });
    if (isHelper) {
      commit(`annotation/${roomInfo.helperOneAndOneDto?.studentId ? "setInfoOneMode" : "setInfo"}`, roomInfo.annotation, { root: true });
    } else {
      commit(`annotation/${roomInfo.oneAndOneDto?.studentId ? "setInfoOneMode" : "setInfo"}`, roomInfo.annotation, { root: true });
    }
    commit(
      "annotation/setDrawings",
      (!isHelper && roomInfo.oneAndOneDto?.studentId ? roomInfo.annotation?.oneOneDrawing?.fabrics : roomInfo.annotation?.drawing.fabrics) ?? [],
      { root: true },
    );
    await dispatch("setStudentOneWithAnotherTeacherId", { id: roomInfo.oneToOneWithAnotherTeacherStudentId });
    const { oneAndOneDto } = roomInfo;
    if (oneAndOneDto) {
      isCurrentUserInOneMode = true;
      const { exposureSelected, itemContentSelected, isShowWhiteBoard } = oneAndOneDto;
      commit("classTeaching/setMyOneAndOneData", oneAndOneDto, { root: true });
      commit(
        "lesson/setCurrentExposure",
        {
          id: exposureSelected,
          preventSelectFirstSlideAutomatically: !!itemContentSelected,
        },
        { root: true },
      );
      commit(
        "lesson/setCurrentExposureItemMedia",
        {
          id: itemContentSelected,
        },
        { root: true },
      );
      isHelper && commit("setWhiteboard", isShowWhiteBoard);
      commit("updateIsPalette", { id: oneAndOneDto.studentId, isPalette: oneAndOneDto.isEnablePalette });
    } else {
      isHelper && commit("setWhiteboard", roomInfo.state.isShowWhiteBoard);
    }
    if (oneAndOneDto?.studentId) {
      await dispatch("teacherRoom/setStudentOneId", { id: oneAndOneDto.studentId }, { root: true });
      await dispatch("lesson/setZoomRatio", oneAndOneDto.ratio ? oneAndOneDto.ratio : MIN_ZOOM_RATIO, {
        root: true,
      });
      await dispatch("lesson/setPdfScrollProgress", oneAndOneDto.ratioScrollPdf ?? 0, {
        root: true,
      });
      if (oneAndOneDto.position) {
        await dispatch(
          "lesson/setImgCoords",
          {
            x: oneAndOneDto.position.x,
            y: oneAndOneDto.position.y,
          },
          { root: true },
        );
      }
    } else {
      await dispatch("lesson/setBothExposureAndSlide", roomInfo.lessonPlan, { root: true });
      await dispatch("lesson/setZoomRatio", roomInfo.lessonPlan.ratio ? roomInfo.lessonPlan.ratio : MIN_ZOOM_RATIO, { root: true });
      await dispatch("lesson/setPdfScrollProgress", roomInfo.state.ratioScrollPdf ?? 0, { root: true });
      if (roomInfo.lessonPlan.position) {
        await dispatch(
          "lesson/setImgCoords",
          {
            x: roomInfo.lessonPlan.position.x,
            y: roomInfo.lessonPlan.position.y,
          },
          { root: true },
        );
      }
      await dispatch("teacherRoom/setStudentOneId", { id: "" }, { root: true });
    }
    await dispatch(
      vuexName(VuexNames.LESSON.DISPATCHES.PROCESS_VISIBLE_TARGETS_DATA_FROM_API),
      isCurrentUserInOneMode ? roomInfo.annotation?.oneOneDrawing?.visibleShapes : roomInfo.annotation?.drawing?.visibleShapes,
      {
        root: true,
      },
    );
  },
  async initClassRoom({ commit, dispatch, rootState }, payload: InitClassRoomPayload) {
    try {
      let isCurrentUserInOneMode = false;
      /** Archive independent mode data */
      const setIndependentModeData = async (currentUnit: number, currentLesson: number) => {
        let response: null | GetIndependentStudentsResponse;
        if (payload.isHelper) {
          response = await IndependentService.helperGetIndependentStudentsItem();
        } else {
          response = await IndependentService.getIndependentStudentsItem();
        }
        commit("classTeaching/setMode", StudyMode.Independent, { root: true });
        commit("teacherTeaching/setIndependentListStudentItems", response.data, { root: true });
        commit("classTeaching/setIndependentModeCurrentUnit", currentUnit, { root: true });
        commit("classTeaching/setIndependentModeCurrentLesson", currentLesson, { root: true });
      };
      const setClassTeachingData = (sessionInfo: { sessionId: string; groupId: string; classId: string }) => {
        commit("classTeaching/setSessionInfo", sessionInfo, { root: true });
      };
      //** This is the only time to check if the teacher has lost connection using our server, then it will rely on AGORA */
      const checkTeacherDisconnect = (teacherCallingConnectionStatus: VideoCallStatus) => {
        commit("setTeacherDisconnected", teacherCallingConnectionStatus === VideoCallStatus.Disconnected);
      };
      /** Archive helper data */
      const setOneToOneWithHelperData = (oneToOneWithHelperDto: OneToOneDto) => {
        const { isIgnoreTeacherAudio } = oneToOneWithHelperDto;
        commit("classTeaching/setOneToOneWithHelperIgnoreTeacherVoice", isIgnoreTeacherAudio, { root: true });
      };
      /** Archive team mode data */
      const setTeamModeData = async (isTeamMode: boolean) => {
        commit("teams/setIsTeamMode", isTeamMode, { root: true });
        await dispatch("teams/getTeams", null, { root: true });
      };
      /** Init the class */
      commit("setUser", { id: payload.userId, name: payload.userName });
      let roomResponse: FetchSessionDtoResModel;
      if (payload.isHelper && payload.groupId) {
        roomResponse = await HelperService.fetchSessionDataAsHelper(payload.groupId, payload.deviceId);
      } else {
        roomResponse = await RemoteTeachingService.fetchSessionDataAsTeacher(payload.deviceId);
      }
      const roomInfo: RoomModel = roomResponse.data;
      if (roomInfo?.classInfo?.classId !== payload.classId) {
        commit("setError", {
          errorCode: GLErrorCode.CLASS_IS_NOT_ACTIVE,
          message: fmtMsg(ErrorLocale.ClassNotStarted),
        });
        return Promise.reject(GLErrorCode.CLASS_IS_NOT_ACTIVE);
      }
      setClassTeachingData({ sessionId: roomInfo.id, classId: roomInfo.classInfo.classId, groupId: roomInfo.classInfo.groupId });
      /** If current lesson is "alternative" type, fetch media state to update the media's progress */
      store.commit("whiteboard/setShouldFetchMediaState", true, { root: true });
      await setTeamModeData(roomInfo.state.isTeamMode);
      if (payload.isHelper && roomInfo.helperOneAndOneDto?.studentId) {
        setOneToOneWithHelperData(roomInfo.helperOneAndOneDto);
      }
      commit("setParamsForHelperJoinSession", {
        deviceId: payload.deviceId,
        groupId: payload.groupId || "",
      });
      if (roomInfo.helper) {
        commit("teacher/setIgnoreHelperRequestJoinClass", true, { root: true });
      }
      commit("setRoomInfo", roomInfo);
      if (payload.isHelper && payload.callFirstTime) {
        checkTeacherDisconnect(roomInfo.teacher.videoCallStatus);
      }
      if (payload.isHelper) {
        commit(`annotation/${roomInfo.helperOneAndOneDto?.studentId ? "setInfoOneMode" : "setInfo"}`, roomInfo.annotation, { root: true });
      } else {
        commit(`annotation/${roomInfo.oneAndOneDto?.studentId ? "setInfoOneMode" : "setInfo"}`, roomInfo.annotation, { root: true });
      }
      commit(
        "annotation/setDrawings",
        (!payload.isHelper && roomInfo.oneAndOneDto?.studentId
          ? roomInfo.annotation?.oneOneDrawing?.fabrics
          : roomInfo.annotation?.drawing.fabrics) ?? [],
        { root: true },
      );

      // TODO: Refactor lesson data handling logic
      if (!payload.callFirstTime && !roomInfo.lessonPlan.contentSelected) {
        // Clear exposure and exposure item if not set on Redis after successful SignalR reconnection
        commit(vuexName(VuexNames.LESSON.COMMITS.CLEAR_EXPOSURE), null, { root: true });
      }
      await dispatch(
        "lesson/setInfo",
        {
          lessonPlan: roomInfo.lessonPlan,
          isSetCurrentExposure: roomInfo.lessonPlan.contentSelected && !roomInfo.oneAndOneDto?.studentId,
        },
        { root: true },
      );
      if (roomInfo.state.independentMode) {
        await setIndependentModeData(roomInfo.classInfo.unit, roomInfo.classInfo.lesson);
      }
      await dispatch("setStudentOneWithAnotherTeacherId", { id: roomInfo.oneToOneWithAnotherTeacherStudentId });
      const { oneAndOneDto } = roomInfo;
      if (oneAndOneDto) {
        isCurrentUserInOneMode = true;
        const { exposureSelected, itemContentSelected, isShowWhiteBoard } = oneAndOneDto;
        store.commit("classTeaching/setMyOneAndOneData", oneAndOneDto, { root: true });
        store.commit("lesson/setCurrentExposure", {
          id: exposureSelected,
          preventSelectFirstSlideAutomatically: !!itemContentSelected,
        });
        store.commit("lesson/setCurrentExposureItemMedia", {
          id: itemContentSelected,
        });
        commit("setWhiteboard", isShowWhiteBoard);
      } else {
        commit("setWhiteboard", roomInfo.state.isShowWhiteBoard);
      }
      if (oneAndOneDto?.studentId) {
        await dispatch("teacherRoom/setStudentOneId", { id: oneAndOneDto.studentId }, { root: true });
        await dispatch("lesson/setZoomRatio", oneAndOneDto.ratio ? oneAndOneDto.ratio : MIN_ZOOM_RATIO, {
          root: true,
        });
        await dispatch("lesson/setPdfScrollProgress", oneAndOneDto.ratioScrollPdf ?? 0, {
          root: true,
        });
        if (oneAndOneDto.position) {
          await dispatch(
            "lesson/setImgCoords",
            {
              x: oneAndOneDto.position.x,
              y: oneAndOneDto.position.y,
            },
            { root: true },
          );
        }
      } else {
        await dispatch("lesson/setZoomRatio", roomInfo.lessonPlan.ratio ? roomInfo.lessonPlan.ratio : MIN_ZOOM_RATIO, { root: true });
        await dispatch("lesson/setPdfScrollProgress", roomInfo.state.ratioScrollPdf ?? 0, { root: true });
        if (roomInfo.lessonPlan.position) {
          await dispatch(
            "lesson/setImgCoords",
            {
              x: roomInfo.lessonPlan.position.x,
              y: roomInfo.lessonPlan.position.y,
            },
            { root: true },
          );
        }
        await dispatch("teacherRoom/setStudentOneId", { id: "" }, { root: true });
      }
      await dispatch(
        vuexName(VuexNames.LESSON.DISPATCHES.PROCESS_VISIBLE_TARGETS_DATA_FROM_API),
        isCurrentUserInOneMode ? roomInfo.annotation?.oneOneDrawing?.visibleShapes : roomInfo.annotation?.drawing?.visibleShapes,
        {
          root: true,
        },
      );
      commit(
        vuexName(VuexNames.CLASS_TEACHING.COMMITS.SET_INITIAL_CLASS_DATA_SNAPSHOT),
        {
          exposureId: roomInfo.lessonPlan.contentSelected,
        },
        {
          root: true,
        },
      );
    } catch (err) {
      Logger.error(err);
      if (rootState.teacherRoom.isDisconnected) return;
      if (err.code === ErrorCode.ConcurrentUserException) {
        await dispatch(
          "modal/confirmReplacementSession",
          async () => {
            const resp = payload.isHelper
              ? await RemoteTeachingService.sessionReplacementAccept4Helper(getDeviceId())
              : await RemoteTeachingService.sessionReplacementAccept4Teacher(getDeviceId());
            if (resp?.success) location.reload();
          },
          { root: true },
        );
        return;
      }
      await router.push(Paths.Teacher);
    }
  },
  async forceOutHelperOneToOne({ rootGetters, dispatch }) {
    const isHelper = rootGetters["teacher/isHelper"];
    const idOneWithAnotherTeacher = rootGetters["teacherRoom/getStudentModeOneWithAnotherTeacherId"];
    const idOne = rootGetters["teacherRoom/getStudentModeOneId"];

    if ((idOneWithAnotherTeacher && !isHelper) || (isHelper && idOne)) {
      await dispatch("sendOneAndOne", {
        status: false,
        id: null,
        isHelper: true,
      });
    }
  },
  setSpeakingUsers({ commit }, payload: { level: number; uid: UID }[]) {
    const validSpeakings: Array<string> = [];
    if (payload) {
      payload.map((item) => {
        if (item.level >= AGORA_MINIMUM_USER_VOLUME) {
          // should check by a level
          validSpeakings.push(item.uid?.toString());
        }
      });
    }
    commit("setSpeakingUsers", { userIds: validSpeakings });
  },
  async setTeacherAudio({ state }, payload: DeviceMediaPayload) {
    try {
      await state.manager?.setMicrophone({ enable: payload.enable });
    } catch (err) {
      Logger.error(err);
      notification.error({
        message: fmtMsg(ErrorLocale.ToggleMicroError),
      });
    }
  },
  async setTeacherVideo({ state }, payload: DeviceMediaPayload) {
    try {
      await state.manager?.setCamera({
        enable: payload.enable,
        videoEncoderConfigurationPreset: "480p",
      });
    } catch (err) {
      Logger.error(err);
      notification.error({
        message: fmtMsg(ErrorLocale.ToggleCameraError),
      });
    }
  },
  setStudentConnectionStatus(store, payload: UserIdPayload) {
    store.commit("setStudentConnectionStatus", payload);
  },
  studentLeftClass(store, payload: UserIdPayload) {
    store.commit("studentLeftClass", payload);
  },
  studentLeaving(store, payload: UserIdPayload) {
    store.commit("studentLeaving", payload);
  },
  studentRaisingHand(store, payload: { id: string; raisingHand: boolean }) {
    store.commit("setStudentRaisingHand", payload);
  },
  async setCurrentExposure({ state, commit }, payload: { id: string }) {
    try {
      await state.manager?.WSClient.sendRequestStartLessonContent(payload.id);
      commit("lesson/setCurrentExposure", { id: payload.id }, { root: true });
    } catch (error) {
      Logger.error(error);
    }
  },
  async endExposure({ state }, payload: { id: string }) {
    await state.manager?.WSClient.sendRequestEndLessonContent(payload.id);
  },
  async requestChangeExposureItem({ state, rootState }, payload: { id: string }) {
    const exposureId = rootState.lesson.currentExposure?.id ?? "";
    try {
      await state.manager?.WSClient.sendRequestSetLessonItemContent({ exposureId, exposureItemId: payload.id });
    } catch (error) {
      Logger.error(error);
      await state.manager?.WSClient.sendRequestSetLessonItemContent({ exposureId, exposureItemId: payload.id });
    }
  },
  setClassAction({ state }, payload: { action: number }) {
    state.manager?.WSClient.sendRequestSetClassAction(payload.action);
  },
  async setPointer({ state, getters }, payload: SendPointerMessage) {
    if (getters["isTeacherUseOnly"]) {
      if (getters["getTeacherOrHelperOneToOne"]) return;
      await state.manager?.WSClient.sendRequestTeacherUseOnlySetPointer(payload);
    } else {
      await state.manager?.WSClient.sendRequestSetPointer(payload);
    }
  },
  async setMode({ state, getters }, payload: { mode: number }) {
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    if (isTeacherUseOnly) {
      await state.manager?.WSClient.sendRequestTeacherUseOnlyUpdateAnnotationMode(payload.mode);
    } else {
      await state.manager?.WSClient.sendRequestUpdateAnnotationMode(payload.mode);
    }
  },
  async sendLastLineAsync({ state, getters }, payload: { drawing: string }) {
    if (!state.info) return;
    try {
      await RemoteTeachingService.teacherDrawLine(
        JSON.stringify(serializeFabricElementWithCustomAttributes(payload.drawing)),
        state.info.id,
        getters["isTeacherUseOnly"],
      );
    } catch (e) {
      Logger.log(e);
    }
  },
  async requestClearBoardAsync({ state, getters }) {
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    if (isTeacherUseOnly) {
      await state.manager?.WSClient.sendRequestTeacherUseOnlyClearAllBrush();
    } else {
      await state.manager?.WSClient.sendRequestClearAllBrush();
    }
  },
  async setZoomSlide({ state, getters }, payload: { ratio: number; position: { x: number; y: number } }) {
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    if (isTeacherUseOnly) {
      await state.manager?.WSClient.sendRequestTeacherUseOnlyZoomSlide(payload);
    } else {
      await state.manager?.WSClient.sendRequestZoomSlide(payload);
    }
  },
  async setMoveZoomedSlide({ state, getters }, payload: { x: number; y: number; viewPortX: number; viewPortY: number }) {
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    if (isTeacherUseOnly) {
      await state.manager?.WSClient.sendRequestTeacherUseOnlyMoveZoomedSlide(payload);
    } else {
      await state.manager?.WSClient.sendRequestMoveZoomedSlide(payload);
    }
  },
  async sendOneAndOne({ state }, payload: { status: boolean; id: string; isHelper: boolean }) {
    await state.manager?.WSClient.sendRequestSetOneToOne(payload);
  },
  setStudentOneId({ commit }, p: { id: string }) {
    commit("setStudentOneId", p);
  },
  setStudentOneWithAnotherTeacherId({ commit }, p: { id: string }) {
    commit("setStudentOneWithAnotherTeacherId", p);
  },
  setWhiteboard({ state }, { isShowWhiteBoard }: { isShowWhiteBoard: boolean }) {
    state.manager?.WSClient.sendRequestSetWhiteboard(isShowWhiteBoard);
  },
  async setMediaState({ state, rootState }, payload: TeacherUpdateAlternateState) {
    const sessionId = rootState.classTeaching.sessionId;
    const model: TeacherUpdateMediaStateRequestModel = {
      ...payload,
      sessionId,
      isOneToOne: !!state.idOne,
    };
    await RemoteTeachingService.teacherUpdateMediaState(model);
  },
  setLaserPath({ state, getters }, payload: SendRealtimePenMessage) {
    if (getters["isTeacherUseOnly"]) {
      if (getters["getTeacherOrHelperOneToOne"]) return;
      state.manager?.WSClient.sendRequestTeacherUseOnlyDrawLaser(payload);
    } else {
      state.manager?.WSClient.sendRequestDrawLaser(payload);
    }
  },
  setOnline({ commit }) {
    commit("setOnline");
  },
  setOffline({ commit, rootState }) {
    if (rootState.app.userRole === UserRole.Teacher) {
      commit("setOffline");
    }
  },
  setListStudentLowBandWidth({ commit }, p: string[]) {
    commit("setListStudentLowBandWidth", p);
  },
  async setShapesForStudent({ state, getters }, payload: Array<string>) {
    if (!state.info) return;
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    try {
      await RemoteTeachingService.teacherAddShape(payload, state.info.id, isTeacherUseOnly);
    } catch (e) {
      Logger.log(e);
    }
  },
  async getAvatarTeacher({ commit }, payload: { teacherId: string }) {
    const response = await InfoService.fetchTeacherAvatarUrl(payload.teacherId);
    if (response) commit("setAvatarTeacher", response);
  },
  async getAvatarHelper({ commit }, payload: { helperId: string }) {
    const response = await InfoService.fetchTeacherAvatarUrl(payload.helperId);
    if (response) commit("setAvatarHelper", response);
  },
  teacherCreateFabricObject({ state, getters }, payload: any) {
    const { objectId } = payload;
    const fabricObject: FabricObject = {
      fabricId: objectId,
      fabricData: JSON.stringify(serializeFabricElementWithCustomAttributes(payload)),
    };
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    if (isTeacherUseOnly) {
      state.manager?.WSClient.sendRequestTeacherUseOnlyCreateFabricObject(fabricObject);
    } else {
      state.manager?.WSClient.sendRequestCreateFabricObject(fabricObject);
    }
  },
  teacherModifyFabricObject({ state, getters }, payload: any) {
    const { objectId } = payload;
    const fabricObject: FabricObject = {
      fabricId: objectId,
      fabricData: JSON.stringify(serializeFabricElementWithCustomAttributes(payload)),
    };
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    if (isTeacherUseOnly) {
      state.manager?.WSClient.sendRequestTeacherUseOnlyModifyFabricObject(fabricObject);
    } else {
      state.manager?.WSClient.sendRequestModifyFabricObject(fabricObject);
    }
  },
  async setLessonAndUnit(
    { commit, state, dispatch, rootGetters, getters },
    p: {
      unit: number;
      lesson: number;
      unitId: number;
      isCompleted: boolean;
    } | null,
  ) {
    if (!state.info?.id) {
      return;
    }

    const updateState = async (roomInfo: RoomModel) => {
      commit({ type: "lesson/clearLessonData" }, { root: true });
      commit("setRoomInfo", roomInfo);
      await dispatch(
        "lesson/setInfo",
        {
          lessonPlan: roomInfo.lessonPlan,
          isSetCurrentExposure: roomInfo.lessonPlan.contentSelected && !roomInfo.oneAndOneDto?.studentId,
        },
        { root: true },
      );
      await dispatch("lesson/setZoomRatio", MIN_ZOOM_RATIO, {
        root: true,
      });
      await dispatch("lesson/setPdfScrollProgress", 0, {
        root: true,
      });
      await dispatch("lesson/setImgCoords", undefined, { root: true });
    };

    if (p) {
      const data: UpdateLessonAndUnitModel = {
        unit: p.unit,
        lesson: p.lesson,
        unitId: p.unitId,
        sessionId: state.info?.id as string,
        isCompleted: p.isCompleted,
      };
      const roomInfo = await RemoteTeachingService.teacherUpdateLessonAndUnit(data);
      await updateState(roomInfo);
      await state.manager?.WSClient.sendRequestUpdateSessionAndUnit();
    } else {
      let roomResponse = null;
      const params: ParamsToJoinCurSessionInterface = getters["getParamsToJoinCurSession"];
      const isHelper = rootGetters["teacher/isHelper"];
      if (isHelper) {
        roomResponse = await HelperService.fetchSessionDataAsHelper(params.groupId, params.deviceId);
      } else {
        roomResponse = await RemoteTeachingService.fetchSessionDataAsTeacher(params.deviceId);
      }
      const roomInfo: RoomModel = roomResponse.data;
      await updateState(roomInfo);
    }
  },
  async setRoomInfo({ commit }, p: FetchSessionDtoResModel) {
    commit("setRoomInfo", p);
  },
  async setPencilPath({ state, getters }, p: SendRealtimePenMessage) {
    if (getters["isTeacherUseOnly"]) {
      if (getters["getTeacherOrHelperOneToOne"]) return;
      await state.manager?.WSClient.sendRequestTeacherUseOnlyDrawPencil(p);
    } else {
      await state.manager?.WSClient.sendRequestDrawPencil(p);
    }
  },
  async teacherRemoveHelper({ state, commit }) {
    try {
      const helperId = state.helper?.id;
      if (!helperId) return;
      const { success, code } = await HelperService.teacherRemoveHelper(helperId);
      // reset the helper state
      if (success) {
        commit("setHelperInfo", undefined);
      } else {
        if (code == GLErrorCode.HELPER_IS_IN_1_1) {
          notification.warning({
            key: state.teacher?.id,
            message: fmtMsg(TeacherClassError.FailRemoveHelper),
          });
        }
      }
    } catch (error) {
      Logger.error(error);
    }
  },
  async toggleHelperMicro({ state }, isOff: boolean) {
    if (!state.info) return;
    try {
      await state.manager?.setMicrophone({ enable: !isOff });
    } catch (error) {
      notification.error({
        message: fmtMsg(ErrorLocale.ToggleMicroError),
      });
    }
  },
  async toggleHelperCamera({ state }, isOff: boolean) {
    if (!state.info) return;
    try {
      await state.manager?.setCamera({
        enable: !isOff,
        videoEncoderConfigurationPreset: "480p",
      });
    } catch (error) {
      Logger.error(error);
      notification.error({
        message: fmtMsg(ErrorLocale.ToggleCameraError),
      });
    }
  },
  async makeHelperTheNewTeacher({ commit }) {
    commit("makeHelperTheNewTeacher");
    commit("teacher/setIgnoreHelperRequestJoinClass", false, { root: true });
  },
  async changeLocalExposureItem({ commit }, payload: { id: string; exposureType?: ExposureDetailType }) {
    /**
     * Update new exposure item and clear the whiteboard in local
     */
    commit("whiteboard/resetState", null, { root: true });
    commit("lesson/setCurrentExposureItemMedia", payload, { root: true });
    commit("teacherRoom/setWhiteboard", false, { root: true });
    commit("annotation/setDrawings", [], { root: true });
    commit("annotation/setClearBrush", {}, { root: true });
    commit("annotation/clearPencilPath", null, { root: true });
    commit("lesson/clearZoomState", null, { root: true });
    commit("lesson/setPdfScrollProgress", 0, { root: true });
    commit(vuexName(VuexNames.LESSON.COMMITS.SET_VISIBLE_TARGET_TAGS), [], { root: true });
  },
  async updateCameraDevice({ state }, { blockReopen }: { blockReopen?: boolean }) {
    await state.manager?.updateCameraDevice();
    if (blockReopen) return;
    await state.manager?.setCamera({
      enable: false,
      videoEncoderConfigurationPreset: "480p",
    });
    await state.manager?.setCamera({
      enable: true,
      videoEncoderConfigurationPreset: "480p",
    });
  },
  async updateMicrophoneDevice({ state }) {
    await state.manager?.updateMicrophoneDevice();
  },
  async updateSpeakerDevice({ state }) {
    await state.manager?.updateSpeakerDevice();
  },
  async toggleOneToOneWithHelperIgnoreTeacherVoice({ state, commit }, ignore: boolean) {
    try {
      await state.manager?.WSClient.sendRequestOneToOneWithHelperIgnoreTeacherVoice(ignore);
      commit("classTeaching/setOneToOneWithHelperIgnoreTeacherVoice", ignore, { root: true });
    } catch (error) {
      Logger.error(error);
    }
  },
  async updateRemotePdfScrollProgress({ state, getters }, progress: number) {
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    if (isTeacherUseOnly) {
      await state.manager?.WSClient.sendRequestTeacherUseOnlyUpdatePdfScrollProgress(progress);
    } else {
      await state.manager?.WSClient.sendRequestUpdatePdfScrollProgress(progress);
    }
  },
  async setSignalRConnectionId({ rootGetters, commit }, connectionId: string) {
    if (rootGetters["teacher/isHelper"]) {
      commit("setHelperSignalRConnectionId", connectionId);
    } else {
      commit("setTeacherSignalRConnectionId", connectionId);
    }
  },
  async requestUpdateCanvasObjectAsync({ state, rootState }, payload: CanvasObjectModel) {
    try {
      await RemoteTeachingService.updateCanvasObject({
        canvasObject: payload,
        sessionId: rootState.classTeaching.sessionId,
        isTeacherUseOnly: state.isTeacherUseOnly,
      });
    } catch (e) {
      Logger.error(e);
    }
  },
  async toggleStudentMediaDevicesAsync({ rootState, state }, payload: ToggleStudentMediaDevicesAsync) {
    const { sessionId } = rootState.classTeaching;
    const { mediaDeviceType } = payload;
    try {
      const resp = await RemoteTeachingService.toggleStudentMediaDevices({ ...payload, sessionId });
      const studentsDonotUpdate = resp.data.disconnectedStudents;
      if (studentsDonotUpdate.length) {
        const studentsDonotUpdateNames = studentsDonotUpdate
          .map((studentId) => state.students.find((student) => student.id === studentId)?.name)
          .filter(Boolean)
          .join(", ");
        showNotification({
          type: AntdNotificationType.WARNING,
          message: fmtMsg(TeacherActionLocale.WaringTitle),
          description: fmtMsg(TeacherActionLocale.WarningUpdateMediaDeviceFailed, {
            studentNames: studentsDonotUpdateNames,
            mediaDeviceType: fmtMsg(
              mediaDeviceType === MediaDeviceTypes.Camera
                ? TeacherActionLocale.WarningMediaDeviceCamera
                : TeacherActionLocale.WarningMediaDeviceMicro,
            ),
          }),
        });
      }
    } catch (e) {
      showAPIErrorNotification(e);
    }
  },

  async toggleStudentPaletteAsync({ rootState, state, commit }, payload: ToggleStudentPaletteAsync) {
    const { sessionId } = rootState.classTeaching;
    try {
      const resp = await RemoteTeachingService.toggleStudentPalette({ ...payload, sessionId });
      if (!resp.success) {
        const studentName = state.students.find((student) => student.id === payload.studentId)?.name;
        showNotification({
          type: AntdNotificationType.WARNING,
          message: fmtMsg(TeacherActionLocale.WaringTitle),
          description: fmtMsg(TeacherActionLocale.WarningUpdatePaletteFailed, {
            studentName,
          }),
        });
        return;
      }
      commit("setStudentPalette", {
        id: payload.studentId,
        isPalette: payload.isEnable,
      });
    } catch (e) {
      showAPIErrorNotification(e);
    }
  },
  async setStudentsBadgeAsync({ rootState }, payload: string[]) {
    try {
      const { sessionId } = rootState.classTeaching;
      await RemoteTeachingService.setStudentsBadge({ sessionId, studentIds: payload });
    } catch (e) {
      showAPIErrorNotification(e);
    }
  },
  async clearStudentRaisingHandAsync({ rootState, commit }, studentId: string) {
    try {
      const { sessionId } = rootState.classTeaching;
      const success = await RemoteTeachingService.clearStudentRaisingHand(sessionId, studentId);
      if (success) {
        commit("setStudentRaisingHand", {
          id: studentId,
          raisingHand: false,
        });
      }
    } catch (e) {
      showAPIErrorNotification(e);
    }
  },
  async handleAnotherTeacherToggleStudentMediaDevices() {
    //
  },
  async handleRemoteUserJoined({ commit, rootState, state }, id: string) {
    const { isHelper: joinAsHelper } = rootState.teacher;
    commit("calling/setCallingUserIds", { id, status: CallingUserStatus.JOINED }, { root: true });
    const student = state.students.find((student) => student.id === id);
    if (student) {
      // notification.info({ message: fmtMsg(TeacherClassLocale.StudentJoinedClass, { studentName: student.englishName }) });
    } else if (joinAsHelper) {
      commit("setTeacherDisconnected", false);
    }
  },
};

export default actions;
