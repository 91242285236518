import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/permission_denied.webp'


const _hoisted_1 = {
  key: 0,
  class: "list-disc pl-5 mb-4"
}
const _hoisted_2 = {
  key: 1,
  class: "list-disc pl-5 mb-4"
}
const _hoisted_3 = {
  key: 2,
  class: "flex"
}
const _hoisted_4 = { class: "list-decimal pl-5 mb-4" }
const _hoisted_5 = {
  key: 3,
  class: "flex"
}
const _hoisted_6 = { class: "list-decimal pl-5 mb-4" }
const _hoisted_7 = {
  key: 4,
  class: "flex"
}
const _hoisted_8 = {
  key: 5,
  class: "flex"
}
const _hoisted_9 = {
  key: 6,
  class: "flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.code === _ctx.CameraCustomErrorCode.CAMERA_DEVICE_IN_USE)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          _createElementVNode("li", null, _toDisplayString(_ctx.MsgCameraInUseDetailed1), 1),
          _createElementVNode("li", null, _toDisplayString(_ctx.MsgCameraInUseDetailed2), 1),
          _createElementVNode("li", null, _toDisplayString(_ctx.MsgDeviceReopenBrowser), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.code === _ctx.MicrophoneCustomErrorCode.MICROPHONE_DEVICE_IN_USE)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          _createElementVNode("li", null, _toDisplayString(_ctx.MsgMicrophoneInUseDetailed1), 1),
          _createElementVNode("li", null, _toDisplayString(_ctx.MsgMicrophoneInUseDetailed2), 1),
          _createElementVNode("li", null, _toDisplayString(_ctx.MsgDeviceReopenBrowser), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.code === _ctx.CameraCustomErrorCode.CAMERA_PERMISSION_DENIED)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _cache[0] || (_cache[0] = _createElementVNode("img", {
            src: _imports_0,
            alt: "",
            class: "w-1/3"
          }, null, -1)),
          _createElementVNode("ul", _hoisted_4, [
            _createElementVNode("li", null, _toDisplayString(_ctx.DevicePressOnIcon), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.MsgCameraPermissionDeniedDetailed2), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.MsgDeviceReloadPage), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.code === _ctx.MicrophoneCustomErrorCode.MICROPHONE_PERMISSION_DENIED)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _cache[1] || (_cache[1] = _createElementVNode("img", {
            src: _imports_0,
            alt: "",
            class: "w-1/3"
          }, null, -1)),
          _createElementVNode("ul", _hoisted_6, [
            _createElementVNode("li", null, _toDisplayString(_ctx.DevicePressOnIcon), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.MsgMicrophonePermissionDeniedDetailed2), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.MsgDeviceReloadPage), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.code === _ctx.MicrophoneCustomErrorCode.MICROPHONE_ENABLE_NOISE_CANCELLING_FAILED)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.MsgMicrophoneEnableNoiseCancellingFailedDetailed), 1))
      : _createCommentVNode("", true),
    (_ctx.code === _ctx.MicrophoneCustomErrorCode.MICROPHONE_NOT_FOUND)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.MsgMicrophoneNotFoundDetailed), 1))
      : _createCommentVNode("", true),
    (_ctx.code === _ctx.CameraCustomErrorCode.CAMERA_NOT_FOUND)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.MsgCameraNotFoundDetailed), 1))
      : _createCommentVNode("", true)
  ]))
}