import { computed, defineComponent, ref } from "vue";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
export interface CustomSelectItem {
  id: number;
  name: string;
  options: {
    fontFamily?: string;
  };
}
export default defineComponent({
  name: "CustomSelect",
  components: { Listbox, ListboxButton, ListboxOption, ListboxOptions, CheckIcon, ChevronUpDownIcon },
  props: {
    selectedItem: {
      type: Object as () => CustomSelectItem,
      required: true,
    },
    items: {
      type: Object as () => CustomSelectItem[],
      required: true,
    },
  },
  emits: ["update:selectedItem"],
  setup(props, { emit }) {
    const selected = computed({
      get: () => props.selectedItem,
      set: (value) => emit("update:selectedItem", value),
    });
    const listRef = ref<InstanceType<typeof ListboxOptions>>();
    const spanRef = ref<HTMLSpanElement | null>(null);
    const manualCloseSelect = () => {
      if (!listRef.value?.$el) return;
      spanRef.value?.click();
    };
    return {
      spanRef,
      selected,
      manualCloseSelect,
      listRef,
    };
  },
});
