import { markRaw } from "vue";
import { MutationTree } from "vuex";
import { ModalAction, ModalState } from "./state";
import { DynamicKeysObject } from "@/utils/type";

const mutations: MutationTree<ModalState> = {
  open(
    state: ModalState,
    payload: {
      title: string;
      view: object;
      actions?: ModalAction[];
      props?: DynamicKeysObject;
      name?: string;
    },
  ) {
    state.isOpen = true;
    state.title = payload.title;
    state.view = markRaw(payload.view);
    state.name = payload.name || "";
    if (payload.actions) {
      state.actions = payload.actions;
    }
    if (payload.props) {
      state.props = payload.props;
    } else {
      state.props = {};
    }
  },
  close(state: ModalState) {
    state.isOpen = false;
    state.name = "";
  },
  setProps(state: ModalState, payload: DynamicKeysObject) {
    state.props = payload;
  },
  setIsSubmitting(state: ModalState, payload: boolean) {
    state.isSubmitting = payload;
  },
};

export default mutations;
