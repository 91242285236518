<template>
  <!-- Whiteboard Box -->
  <div class="relative rounded-md overflow-hidden w-canvas shadow" oncontextmenu="return false">
    <div class="w-full h-canvas bg-white flex items-center justify-center" ref="whiteBoardRef" v-show="!isWhiteboardShrink">
      <div id="canvas-container" class="w-canvas h-canvas relative flex" @mouseover="handleMouseOver" @mouseout="handleMouseOut">
        <div class="absolute z-50" v-if="isHelper && isPointerMode" :style="[pointerStyle]">
          <img src="@/assets/icon-select.png" alt="" class="absolute w-full h-full object-contain left-[-45%]" />
        </div>
        <div v-if="mediaTypeId === undefined && image?.url">
          <CropImage
            v-if="isCropImage"
            :imageUrl="image.url"
            :metadata="image.metaData"
            :canvasImage="image"
            class="annotation-img"
            @img-load="onLPSlideLoaded"
            ref="imgCropRef"
            @img-error="onErrorLoadSlide"
            :key="`crop-${reloadKey}`"
          />
          <img
            v-else
            crossorigin="anonymous"
            ref="imgRef"
            :src="image.url"
            :key="reloadKey"
            @error="onErrorLoadSlide"
            alt=""
            class="annotation-img"
            @load="onLPSlideLoaded"
          />
        </div>
        <div
          v-else-if="mediaTypeId === MediaType.pdf && image && typeof image.url === 'string' && isValidUrl"
          :style="{
            'z-index': isWbVisible ? -1 : 200,
          }"
          class="absolute inset-0"
        >
          <VuePdfEmbed
            :source="image?.url"
            class="w-full h-full object-contain overflow-auto nice-scroll pdf-config"
            ref="pdfRef"
            @rendered="onPdfRendered"
          />
        </div>
        <div
          v-else-if="mediaTypeId === MediaType.mp3 && image && typeof image.url === 'string' && isValidUrl"
          :style="{
            'z-index': isWbVisible ? -1 : 200,
          }"
          class="absolute object-contain bottom-[5%] left-1/2 -translate-x-1/2"
        >
          <img
            crossorigin="anonymous"
            v-if="currentAudioBackgroundUrl"
            ref="imgRef"
            :src="currentAudioBackgroundUrl"
            class="annotation-img"
            @load="onLPSlideLoaded"
            :key="reloadKey"
            @error="onErrorLoadSlide"
            alt=""
          />
          <audio
            ref="audio"
            :id="ALTERNATE_AUDIO_ID"
            controls
            controlslist="noplaybackrate nodownload"
            class="audio-config"
            @timeupdate="onAudioTimeUpdate"
            :data-slide-id="currentExposureItemMedia?.id"
            :volume="volume"
          >
            <source :src="image?.url" type="audio/mp3" />
            {{ WarningAudioTagText }}
          </audio>
        </div>
        <div
          v-else-if="mediaTypeId === MediaType.mp4 && image && typeof image.url === 'string' && isValidUrl"
          :style="{
            'z-index': isWbVisible ? -1 : 200,
          }"
          class="absolute inset-0 flex justify-center items-center bg-white"
        >
          <video
            ref="video"
            :id="ALTERNATE_VIDEO_ID"
            controls
            controlslist="noplaybackrate nodownload"
            class="video-config"
            oncontextmenu="return false"
            :poster="image?.thumbnail"
            :data-slide-id="currentExposureItemMedia?.id"
            :volume="volume"
          >
            <source :src="image?.url" type="video/mp4" />
            {{ WarningVideoTagText }}
          </video>
        </div>
        <div class="relative z-20">
          <canvas class="canvas-designate" id="canvasDesignate" />
        </div>
        <div class="absolute top-2 left-1/2 -translate-x-1/2 text-red-800 text-sm z-40 pointer-events-none" v-if="isTeacherUseOnly">
          {{ forTeacherUseOnlyText }}
        </div>
        <div class="absolute top-2.5 left-2.5 helper-interaction-allowed" v-if="focusWordContent.condition">
          <Popover placement="rightTop">
            <template #content>
              <div style="font-size: 20px; font-weight: 500">{{ focusWordContent.word }}</div>
              <div>{{ focusWordContent.definition }}</div>
            </template>
            <div class="cursor-pointer">
              <InformationCircleIcon class="w-6 h-6 text-gs-purple" />
            </div>
          </Popover>
        </div>
      </div>
    </div>
    <div class="bg-white border-t border-t-slate-200 text-slate-600 px-6 flex justify-between items-center gap-x-1.5 text-center py-2 text-sm">
      <div class="flex justify-center items-center gap-x-2 text-center">
        <span>{{ ShowWhiteboardText }}</span>
        <Switch
          :checked="isWbVisible"
          @click="
            () => {
              isWbVisible ? hideWhiteboard() : showWhiteboard();
            }
          "
          :class="[
            isWbVisible ? 'bg-gs-purple' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gs-purple focus:ring-offset-2',
          ]"
        >
          <span
            aria-hidden="true"
            :class="[
              isWbVisible ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            ]"
          ></span>
        </Switch>
      </div>
      <div v-if="image && image.metaData && !isWbVisible && hasTargets" class="flex items-center space-x-2.5">
        <span>{{ targetText }} {{ targetsNum }}</span>
        <div class="flex items-center space-x-1">
          <Tooltip :title="PreviewTargetText">
            <div
              :class="[disablePreviewBtn ? 'bg-slate-50 text-slate-300' : 'bg-slate-100 text-slate-600 cursor-pointer hover:bg-white hover:shadow']"
              class="h-6 w-6 rounded-lg bg-slate-100 flex justify-center items-center"
              @click="showHidePreviewModal"
            >
              <ViewfinderCircleIcon class="h-4 w-4" />
            </div>
          </Tooltip>
          <Tooltip :title="MsgShowAllTargetsBtn">
            <div
              :class="[
                'h-6 w-6 rounded-lg flex justify-center items-center',
                isAllTargetsOnCanvasVisible ? 'bg-slate-50 text-slate-300' : 'bg-slate-100 text-slate-600 cursor-pointer hover:bg-white hover:shadow',
              ]"
              @click="handleClickToggleAllTargetsBtn(true)"
            >
              <EyeIcon class="h-4 w-4" />
            </div>
          </Tooltip>
          <Tooltip :title="MsgHideAllTargetsBtn">
            <div
              :class="[
                'h-6 w-6 rounded-lg bg-slate-100 flex justify-center items-center',
                isAllTargetsOnCanvasTransparent
                  ? 'bg-slate-50 text-slate-300'
                  : 'bg-slate-100 text-slate-600 cursor-pointer hover:bg-white hover:shadow',
              ]"
              @click="handleClickToggleAllTargetsBtn(false)"
            >
              <EyeSlashIcon class="h-4 w-4" />
            </div>
          </Tooltip>
        </div>
      </div>
      <div v-if="!isPreventDrawOnCanvas" class="flex items-center space-x-2.5 transition-opacity">
        <span>{{ zoomPercentage }}%</span>
        <div class="flex items-center space-x-1">
          <div class="h-6 w-6 rounded-lg bg-slate-100 hover:bg-white hover:shadow flex justify-center items-center" @click="zoomOut">
            <MagnifyingGlassMinusIcon class="cursor-pointer h-4 w-4" />
          </div>
          <div class="h-6 w-6 rounded-lg bg-slate-100 hover:bg-white hover:shadow flex justify-center items-center" @click="zoomIn">
            <MagnifyingGlassPlusIcon class="cursor-pointer h-4 w-4" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isHelper" class="absolute inset-0 cursor-not-allowed helper-interaction-disabled" />
  </div>
  <!-- Whiteboard Box -->
  <!-- Palette Box -->
  <div v-if="!isHelper && !isWhiteboardShrink" :class="['relative transition-opacity', { 'opacity-50': isPreventDrawOnCanvas }]">
    <Tooltip v-if="isPreventDrawOnCanvas" :title="isCurrentLessonPdf ? MsgUnsupportedPdf : MsgUnsupportedVideo">
      <div class="absolute inset-0 z-10"></div>
    </Tooltip>
    <div v-if="!isTextBoxSelected">
      <ToolsCanvas
        :tool-selected="toolSelected"
        :stroke-width="strokeWidth"
        :stroke-color="strokeColor"
        :isPreventDrawOnCanvas="isPreventDrawOnCanvas"
        @tool-selected="clickedTool"
        @update-color="updateColorValue"
        @update-stroke="updateStrokeWidth"
      />
    </div>
    <div v-else>
      <TextControl
        :controlTextState="controlTextState"
        :isPreventDrawOnCanvas="isPreventDrawOnCanvas"
        @close-text-control="closeTextBoxControl"
        @update-control-text-state="updateControlTextState"
      />
    </div>
    <div v-if="isHelper" class="absolute inset-0 cursor-not-allowed"></div>
  </div>
  <!-- Palette Box -->
</template>
<script lang="ts" src="./whiteboard-palette.ts"></script>
<style scoped>
.video-config {
  width: 100%;
  height: 100%;
}

.video-config[poster] {
  object-fit: contain;
}

.annotation-img {
  @apply w-full h-full object-contain absolute inset-0 mx-auto z-10 hidden;
}

.helper-interaction-allowed {
  z-index: 301 !important;
}

.helper-interaction-disabled {
  z-index: 300 !important;
}
</style>
