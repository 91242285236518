import { OnTeacherUpdateStudentBadgeItem } from "./../interface";
import { TeacherRoomManager } from "@/manager/room/teacher.manager";
import { RoomSessionModel, ClassModel, HelperModel, RoomModel, TeachingMode } from "@/models";
import { GLError } from "@/models/error.model";
import { UserModel } from "@/models/user.model";
import { MutationTree } from "vuex";
import {
  DefaultPayload,
  ConnectionStatus,
  InClassStatus,
  OnTeacherToggleStudentPalette,
  ParamsToJoinCurSessionInterface,
  UserIdPayload,
} from "../interface";
import { ClassAction, ClassActionFromValue } from "../student/state";
import { initState, TeacherRoomState } from "./state";
import { cloneDeep } from "lodash";
import { RoomOptions } from "@/manager/room/base.manager";

type State = TeacherRoomState;
export interface TeacherRoomMutationInterface<S> {
  endClass(s: S): void;
  setTeachingMode(s: S, p: TeachingMode): void;
  setError(s: S, p: GLError | null): void;
  setClasses(s: S, p: Array<ClassModel>): void;
  setUser(s: S, p: UserModel): void;
  setRoomInfo(s: S, p: RoomModel): void;
  resetManagerClient(s: S): void;
  setStudentPalette(s: S, p: OnTeacherToggleStudentPalette): void;
  setStudentConnectionStatus(s: S, p: { id: string; status: InClassStatus }): void;
  studentLeftClass(s: S, p: UserIdPayload): void;
  studentLeaving(s: S, p: UserIdPayload): void;
  setStudentRaisingHand(s: S, p: { id: string; raisingHand: boolean }): void;
  setWhiteboard(s: S, p: boolean): void;
  setIsTeacherUseOnly(s: S, p: boolean): void;
  makeHelperTheNewTeacher(s: S, p: DefaultPayload): void;
}

export interface TeacherRoomMutation<S> extends MutationTree<S>, TeacherRoomMutationInterface<S> {}

const mutations: TeacherRoomMutation<State> = {
  endClass(s: State): void {
    s.manager?.close(true);
    Object.assign(s, initState());
  },
  leaveClass(s: State): void {
    s.manager?.close(false);
    Object.assign(s, initState());
  },
  setTeachingMode(state: State, mode: TeachingMode): void {
    state.teachingMode = mode;
  },
  setError(s: State, p: GLError | null): void {
    s.error = p;
  },
  setClasses(s: State, p: ClassModel[]): void {
    s.classes = p;
  },
  setUser(s: State, p: UserModel): void {
    s.user = p;
  },
  setRoomInfo(s: State, p: RoomSessionModel): void {
    const isActionUpToDate = p.options?.isActionUpToDate;
    const isHelper = p.options?.isHelper;
    const blockTeacherUpdate = isActionUpToDate && !isHelper;
    const blockHelperUpdate = isActionUpToDate && isHelper;
    s.info = p;
    s.idOne = p.oneAndOneDto?.studentId ? p.oneAndOneDto?.studentId : "";
    s.currentLesson = p.classInfo?.lesson;
    s.currentUnit = p.classInfo?.unit;
    // No need to up-to-date myself when this mutation is called from the action "upToDateSessionAfterSignalRConnected"
    if (!blockTeacherUpdate) {
      s.teacher = {
        id: p.teacher.id,
        name: p.teacher.name,
        avatar: s.teacher?.avatar || "",
        status: p.teacher.signalRStatus,
        videoCallStatus: p.teacher.videoCallStatus,
        disconnectTime: p.teacher.disconnectTime ? Date.now() - p.teacher.disconnectTime : null,
        signalRConnectionId: s.teacher?.signalRConnectionId ?? null,
      };
    }
    if (p.helper) {
      // No need to up-to-date myself when this mutation is called from the action "upToDateSessionAfterSignalRConnected"
      if (!blockHelperUpdate) {
        s.helper = {
          id: p.helper.id,
          name: p.helper.name,
          avatar: s.helper?.avatar || "",
          signalRStatus: p.helper.signalRStatus,
          videoCallStatus: p.helper.videoCallStatus,
          isVideoShownByTeacher: p.helper.isVideoShownByTeacher,
          signalRConnectionId: s.helper?.signalRConnectionId ?? null,
        };
      }
    } else {
      s.helper = undefined;
    }
    s.teachingMode = p.state.teachingMode;
    s.students = p.students.map((st, index) => {
      return {
        id: st.id,
        name: st.name,
        englishName: st.englishName,
        avatar: "",
        badge: st.badge,
        status: st.signalRStatus,
        videoCallStatus: st.videoCallStatus,
        index: index,
        raisingHand: st.isRaisingHand,
        isPalette: st.isPalette,
      };
    });
    // If joining as a teacher, create a new TeacherRoomManager with role "host"
    const role = p.streamInfo?.userId == p.teacher.id ? "host" : "audience";
    if (!s.manager) {
      const teacherRoomManagerParams: RoomOptions = {
        agora: {
          appId: p.streamInfo.appId,
          webConfig: { mode: "rtc", codec: "vp8", role: role },
          user: {
            channel: p.streamInfo.chanelId,
            username: p.streamInfo.userId,
            token: p.streamInfo.token,
            role: role,
          },
        },
      };
      s.manager = new TeacherRoomManager(teacherRoomManagerParams);
    }
    s.classAction = ClassActionFromValue(p.lessonPlan.lessonAction);
  },
  resetManagerClient(s: State): void {
    s.manager = undefined;
  },
  setStudentsBadge(s: State, p: OnTeacherUpdateStudentBadgeItem[]): void {
    p.forEach((item) => {
      const student = s.students.find((st) => st.id === item.id);
      if (student) student.badge = item.badge;
    });
  },
  setStudentConnectionStatus(state: State, payload: { id: string; status: InClassStatus }) {
    const student = state.students.find((st) => st.id === payload.id);
    if (student) student.status = payload.status;
  },
  updateRaisingHand(state: State, payload: { id: string; isRaisingHand: boolean }): void {
    const student = state.students.find((student) => student.id === payload.id);
    if (student) {
      student.raisingHand = payload.isRaisingHand;
    }
  },
  updateIsPalette(state: State, payload: { id: string; isPalette: boolean }) {
    const student = state.students.find((student) => student.id === payload.id);
    if (student) {
      student.isPalette = payload.isPalette;
    }
  },
  studentLeftClass(s: State, p: UserIdPayload): void {
    const student = s.students.find((student) => student.id === p.id);
    if (student) {
      student.raisingHand = false;
      student.isPalette = false;
      student.status = InClassStatus.LEFT;
    }
  },
  studentLeaving(s: State, p: UserIdPayload): void {
    const student = s.students.find(({ id }) => id === p.id);
    if (student) {
      student.raisingHand = false;
      student.isPalette = false;
      student.status = InClassStatus.LEAVING;
    }
  },
  setStudentRaisingHand(s: State, p: { id: string; raisingHand: boolean }): void {
    const student = s.students.find((student) => student.id === p.id);
    if (student) student.raisingHand = p.raisingHand;
  },
  setClassAction(state: State, payload: { action: ClassAction }) {
    state.classAction = payload.action;
  },
  setStudentOneId(s: State, p: { id: string }) {
    s.idOne = p.id;
  },
  setStudentOneWithAnotherTeacherId(s: State, p: { id: string }) {
    s.idOneWithAnotherTeacher = p.id;
  },
  setSpeakingUsers(s: State, p: { userIds: Array<string> }) {
    s.speakingUsers = p.userIds;
  },
  setStudentPalette(s: TeacherRoomState, p: OnTeacherToggleStudentPalette) {
    // If the student is a palette, then disable all other students' palette
    if (p.isPalette) {
      s.students.forEach((student) => {
        if (student.id !== p.id) {
          student.isPalette = false;
        }
      });
    }
    // Then set the palette status of the student
    const student = s.students.find((st) => st.id === p.id);
    if (student) student.isPalette = p.isPalette;
  },
  disableAllAnnotationStatus(s: TeacherRoomState, p: any) {
    s.students.forEach((student) => (student.isPalette = !p));
  },
  setOnline(state: TeacherRoomState) {
    state.isDisconnected = false;
  },
  setOffline(state: TeacherRoomState) {
    state.isDisconnected = true;
  },
  setListStudentLowBandWidth(state: TeacherRoomState, p) {
    state.listStudentLowBandWidth = p;
  },
  setWhiteboard(state: TeacherRoomState, p) {
    state.isShowWhiteboard = p;
  },
  setMediaState(state: TeacherRoomState, p) {
    state.mediaState = p;
  },
  setAvatarTeacher(state: TeacherRoomState, p: string) {
    if (state.teacher) state.teacher.avatar = p;
  },
  setAvatarHelper(state: TeacherRoomState, p: string) {
    if (state.helper) state.helper.avatar = p;
  },
  setTeacherDisconnected(state: TeacherRoomState, payload: boolean) {
    state.teacherIsDisconnected = payload;
    if (!payload && state.teacher) {
      state.teacher.disconnectTime = null;
    }
  },
  setIsTeacherUseOnly(state: TeacherRoomState, p) {
    state.isTeacherUseOnly = p;
  },
  setHelperInfo(s: TeacherRoomState, p: HelperModel | undefined) {
    if (!p) {
      s.helper = p;
    } else {
      s.helper = {
        id: p.id,
        name: p.name,
        avatar: s.helper?.avatar || "",
        signalRStatus: p.signalRStatus,
        videoCallStatus: p.videoCallStatus,
        isVideoShownByTeacher: p.isVideoShownByTeacher,
        signalRConnectionId: p.signalrConnectId,
      };
    }
  },
  setHelperConnectionStatus(s: TeacherRoomState, p: ConnectionStatus) {
    if (s.helper) {
      s.helper.signalRStatus = p;
    }
  },
  setHelperVideoStatus(s: TeacherRoomState, p: boolean) {
    if (s.helper) {
      s.helper.isVideoShownByTeacher = p;
    }
  },
  setParamsForHelperJoinSession(s: TeacherRoomState, payload: ParamsToJoinCurSessionInterface) {
    s.paramsToJoinCurSession = payload;
  },
  setTeacherSignalRConnectionId(s: TeacherRoomState, id: string) {
    if (s.teacher) {
      s.teacher.signalRConnectionId = id;
    }
  },
  setHelperSignalRConnectionId(s: TeacherRoomState, id: string) {
    if (s.helper) {
      s.helper.signalRConnectionId = id;
    }
  },
  makeHelperTheNewTeacher(s: TeacherRoomState) {
    if (s.helper) {
      s.teacher = {
        ...cloneDeep(s.helper),
        status: InClassStatus.JOINED,
      };
      s.helper = undefined;
    }
  },
  setIsSessionEnded(s: TeacherRoomState, loading: boolean) {
    s.isSessionEnded = loading;
  },
};

export default mutations;
