<template>
  <div class="bg-white rounded-lg flex justify-around gap-x-4 py-2 px-6 shadow">
    <Tooltip :title="MsgCursor">
      <div :class="['tool-item', toolSelected === 'cursor' && 'selected']" @click="clickedTool('cursor')">
        <img src="@/assets/tools/Cursor.svg" alt="Cursor Icon" />
      </div>
    </Tooltip>
    <Tooltip :title="MsgText">
      <div :class="['tool-item', toolSelected === 'TextBox' && 'selected']" @click="clickedTool('TextBox')">
        <img src="@/assets/tools/InputText_Icon.png" alt="Text Icon" />
      </div>
    </Tooltip>
    <Tooltip :title="MsgLaserPen">
      <div :class="['tool-item', toolSelected === 'laser' && 'selected']" @click="clickedTool('laser')">
        <img src="@/assets/tools/LaserPointer.svg" alt="Laser Icon" />
      </div>
    </Tooltip>
    <Tooltip :title="MsgPencil">
      <div :class="['tool-item', toolSelected === 'pen' && 'selected']" @click="clickedTool('pen')">
        <img src="@/assets/tools/Pencil.svg" alt="Pen Icon" />
      </div>
    </Tooltip>

    <Tooltip :title="MsgSize" :mouseLeaveDelay="0">
      <Popover v-model:visible="showListSize" trigger="click">
        <template #content>
          <Space direction="vertical">
            <div
              class="px-2 py-1 cursor-pointer"
              v-for="(size, index) in strokeSize"
              :key="index"
              @click="
                updateStrokeSize(size);
                hideListSize();
              "
            >
              <div class="w-10 bg-slate-600" :style="`height: ${size * 1.2}px`"></div>
            </div>
          </Space>
        </template>
        <div
          :class="['relative tool-item', toolSelected === 'stroke' && 'selected', strokeWidth === 2 && 'thin', strokeWidth === 4 && 'thick']"
          @click="clickedTool('stroke')"
        >
          <img src="@/assets/tools/StrokeSize.svg" alt="Size Icon" />
        </div>
      </Popover>
    </Tooltip>
    <Tooltip :title="MsgColor" :mouseLeaveDelay="0">
      <Popover v-model:visible="showListColors" trigger="click blur">
        <template #content>
          <div class="grid grid-cols-2 gap-2.5">
            <div
              v-for="(color, index) in colorsList"
              :key="index"
              :style="`background-color: ${color}`"
              class="w-5 h-5 rounded-full shadow cursor-pointer transition-transform border border-slate-200 hover:scale-125"
              @click="
                updateColor(color);
                hideListColors();
              "
            />
          </div>
        </template>
        <div :class="['relative tool-item', toolSelected === 'stroke-color' && 'selected']" @click="clickedTool('stroke-color')">
          <img :src="require(`@/assets/tools/ColorSelectScribble.svg`)" alt="Color Icon" />
          <div
            class="absolute w-3 h-3 bottom-1 right-0 border border-slate-200 rounded-full shadow"
            :style="`background-color: ${strokeColor}`"
          ></div>
        </div>
      </Popover>
    </Tooltip>
    <Tooltip :title="MsgCircle">
      <div :class="['tool-item', toolSelected === 'circle' && 'selected']" @click="clickedTool('circle')">
        <img src="@/assets/tools/Circle.svg" alt="Circle Icon" />
      </div>
    </Tooltip>
    <Tooltip :title="MsgSquare">
      <div :class="['tool-item', toolSelected === 'square' && 'selected']" @click="clickedTool('square')">
        <img src="@/assets/tools/Square.svg" alt="Square Icon" />
      </div>
    </Tooltip>
    <Tooltip :title="MsgUndo">
      <div :class="['tool-item', toolSelected === 'delete' && 'selected']" @click="clickedTool('delete')">
        <ArrowUturnLeftIcon class="h-5 w-5" :class="[toolSelected === 'delete' ? 'text-white' : 'text-black/60']" />
      </div>
    </Tooltip>
    <Tooltip :title="MsgClear">
      <div class="tool-item" @click="clickedTool('clear')">
        <img src="@/assets/tools/EraseAll.svg" alt="Clear Icon" />
      </div>
    </Tooltip>
  </div>
</template>
<script lang="ts" src="./tools-canvas.ts"></script>
<style lang="scss" scoped>
.tool-item {
  @apply h-8 w-8 flex justify-center items-center rounded-lg;
  &.selected {
    @apply bg-gs-purple text-white;
    img {
      filter: invert(100%) sepia(0%) saturate(7493%) hue-rotate(306deg) brightness(107%) contrast(101%); // text-white
    }
  }

  &:not(.selected) {
    @apply text-slate-500 cursor-pointer;
    img {
      @apply opacity-60;
    }
  }

  > img {
    @apply h-5 w-5;
  }

  &.thin,
  &.thick {
    &:before {
      content: "";
      width: 3px;
      height: 3px;
      background-color: #000;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 10px;
    }
  }

  &.thick {
    &:before {
      top: unset;
      bottom: 10px;
    }
  }
}
</style>
