import { DeviceType } from "@/components/common/device-settings/device-settings";
import { isVirtualMediaDevice } from "@/utils/utils";
import { fmtMsg } from "vue-glcommonui";
import { MediaDeviceErrors } from "@/locales/localeid";

export interface MediaDeviceError {
  isError: boolean;
  customMessage: string;
  code: string;
}

export const createDefaultMediaError = (): MediaDeviceError => ({ isError: false, customMessage: "", code: "" });

export enum CameraCustomErrorCode {
  CAMERA_DEVICE_IN_USE = "CAMERA_DEVICE_IN_USE",
  CAMERA_PERMISSION_DENIED = "CAMERA_PERMISSION_DENIED",
  CAMERA_NOT_FOUND = "CAMERA_NOT_FOUND",
}

export enum MicrophoneCustomErrorCode {
  MICROPHONE_DEVICE_IN_USE = "MICROPHONE_DEVICE_IN_USE",
  MICROPHONE_PERMISSION_DENIED = "MICROPHONE_PERMISSION_DENIED",
  MICROPHONE_ENABLE_NOISE_CANCELLING_FAILED = "MICROPHONE_ENABLE_NOISE_CANCELLING_FAILED",
  MICROPHONE_NOT_FOUND = "MICROPHONE_NOT_FOUND",
}

//#region Microphone Error Handling
export const mappingAgoraMicrophoneError = (error: any): MediaDeviceError => {
  if (!error || typeof error !== "object") {
    return {
      isError: true,
      customMessage: fmtMsg(MediaDeviceErrors.MicrophoneInUse),
      code: MicrophoneCustomErrorCode.MICROPHONE_DEVICE_IN_USE,
    };
  }

  const lowerMessage = error.message ? error.message.toLowerCase() : "";

  if (lowerMessage.includes("device in use")) {
    return {
      isError: true,
      customMessage: fmtMsg(MediaDeviceErrors.MicrophoneInUse),
      code: MicrophoneCustomErrorCode.MICROPHONE_DEVICE_IN_USE,
    };
  }

  if (lowerMessage.includes("permission denied")) {
    return {
      isError: true,
      customMessage: fmtMsg(MediaDeviceErrors.MicrophonePermissionDenied),
      code: MicrophoneCustomErrorCode.MICROPHONE_PERMISSION_DENIED,
    };
  }

  if (lowerMessage.includes("aidenoiserprocessor")) {
    return {
      isError: true,
      customMessage: fmtMsg(MediaDeviceErrors.MicrophoneEnableNoiseCancellingFailed),
      code: MicrophoneCustomErrorCode.MICROPHONE_ENABLE_NOISE_CANCELLING_FAILED,
    };
  }

  if (lowerMessage.includes("microphone_not_found")) {
    return {
      isError: true,
      customMessage: fmtMsg(MediaDeviceErrors.MicrophoneNotFound),
      code: MicrophoneCustomErrorCode.MICROPHONE_NOT_FOUND,
    };
  }

  return { isError: true, code: MicrophoneCustomErrorCode.MICROPHONE_DEVICE_IN_USE, customMessage: fmtMsg(MediaDeviceErrors.MicrophoneInUse) };
};
//#endregion

//#region Camera Error Handling
export const mappingAgoraCameraError = (error: any): MediaDeviceError => {
  if (!error || typeof error !== "object") {
    return {
      isError: true,
      customMessage: fmtMsg(MediaDeviceErrors.CameraInUse),
      code: CameraCustomErrorCode.CAMERA_DEVICE_IN_USE,
    };
  }

  const lowerMessage = error.message ? error.message.toLowerCase() : "";

  if (lowerMessage.includes("device in use")) {
    return {
      isError: true,
      customMessage: fmtMsg(MediaDeviceErrors.CameraInUse),
      code: CameraCustomErrorCode.CAMERA_DEVICE_IN_USE,
    };
  }

  if (lowerMessage.includes("camera_not_found")) {
    return {
      isError: true,
      customMessage: fmtMsg(MediaDeviceErrors.CameraNotFound),
      code: CameraCustomErrorCode.CAMERA_NOT_FOUND,
    };
  }

  if (lowerMessage.includes("permission denied")) {
    return {
      isError: true,
      customMessage: fmtMsg(MediaDeviceErrors.CameraPermissionDenied),
      code: CameraCustomErrorCode.CAMERA_PERMISSION_DENIED,
    };
  }

  return { isError: true, code: CameraCustomErrorCode.CAMERA_DEVICE_IN_USE, customMessage: fmtMsg(MediaDeviceErrors.CameraInUse) };
};
//#endregion

export function filterAndSortMicrophones(microphones: MediaDeviceInfo[]): DeviceType[] {
  return microphones
    .filter((microphone) => !isVirtualMediaDevice(microphone.label))
    .map((microphone) => ({
      deviceId: microphone.deviceId,
      label: microphone.label,
      kind: microphone.kind,
      groupId: microphone.groupId || "",
      isDefault: microphone.deviceId === "default",
    }));
}

export function filterAndSortSpeakers(speakers: MediaDeviceInfo[]): DeviceType[] {
  return speakers
    .filter((speaker) => !isVirtualMediaDevice(speaker.label))
    .map((speaker) => ({
      deviceId: speaker.deviceId,
      label: speaker.label,
      kind: speaker.kind,
      groupId: speaker.groupId || "",
      isDefault: speaker.deviceId === "default",
    }));
}
