import { ref } from "vue";

export const useDeviceToggling = () => {
  const isCameraToggling = ref(false);
  const isMicrophoneToggling = ref(false);

  const toggleCamera = async (callback: () => Promise<void>) => {
    isCameraToggling.value = true;
    // do some async operation
    await callback();
    isCameraToggling.value = false;
  };

  const toggleMicrophone = async (callback: () => Promise<void>) => {
    isMicrophoneToggling.value = true;
    // do some async operation
    await callback();
    isMicrophoneToggling.value = false;
  };

  return {
    isCameraToggling,
    isMicrophoneToggling,
    toggleCamera,
    toggleMicrophone,
  };
};
