import { debounce } from "lodash";
import IconAudioOff from "@/assets/student-class/audio-off.svg";
import IconAudioOn from "@/assets/student-class/audio-on.svg";
import IconVideoOff from "@/assets/student-class/video-off.svg";
import IconVideoOn from "@/assets/student-class/video-on.svg";
import IconCog from "@/assets/teacher-class/cog.svg";
import IconLowWifi from "@/assets/teacher-class/slow-wifi.svg";
import { TeacherState } from "@/store/room/interface";
import { generateAvatar } from "@/utils/utils";
import { CheckOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import { Button, Modal, Row, Space, Spin } from "ant-design-vue";
import { computed, defineComponent, ref, watch } from "vue";
import { LoginInfo } from "vue-glcommonui";
import { useStore } from "vuex";
import {
  VideoCameraIcon,
  VideoCameraSlashIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
  XMarkIcon,
  Cog6ToothIcon,
  ArrowsPointingInIcon,
  WifiIcon,
} from "@heroicons/vue/20/solid";
import NameTag from "@/components/common/name-tag/name-tag.vue";
import { useAgoraVideoSpinner } from "@/hooks/use-agora-video-spinner";
import { isUserPublishedAudio, isUserPublishedVideo } from "@/store/calling/utils";
import { DeviceErrorTooltip } from "@/components/common/device-settings/components";
import { MediaDeviceError } from "@/agora/utils";
import { vuexName, VuexNames } from "@/store/utils";
import { useDeviceToggling } from "@/hooks/useDeviceToggling";
import { DynamicModalNames } from "@/store/modal/actions";

export default defineComponent({
  name: "TeacherClassTeacherCard",
  props: {
    teacher: {
      type: Object as () => TeacherState,
      required: true,
    },
    isMinimized: Boolean,
    onClickMinimized: {
      type: Object as () => void,
      required: true,
    },
  },
  components: {
    LoadingOutlined,
    NameTag,
    Button,
    Row,
    CheckOutlined,
    Space,
    Spin,
    Modal,
    VideoCameraIcon,
    VideoCameraSlashIcon,
    SpeakerWaveIcon,
    SpeakerXMarkIcon,
    XMarkIcon,
    Cog6ToothIcon,
    ArrowsPointingInIcon,
    WifiIcon,
    DeviceErrorTooltip,
  },
  setup: function (props) {
    const { getters, dispatch, commit } = useStore();
    const { toggleMicrophone, toggleCamera, isCameraToggling, isMicrophoneToggling } = useDeviceToggling();
    const isJoinedSession = computed<boolean>(() => getters[vuexName(VuexNames.CALLING.GETTERS.GET_IS_JOINED_SESSION)]);
    const dynamicModalName = computed<string>(() => getters[vuexName(VuexNames.MODAL.GETTERS.NAME)]);
    const cameraError = computed<MediaDeviceError>(() => getters["calling/getCameraError"]);
    const microphoneError = computed<MediaDeviceError>(() => getters["calling/getMicrophoneError"]);
    const { videoRef, spinnerVisible } = useAgoraVideoSpinner();
    const isDeviceSettingsModalShown = ref(false);
    const isInOneToOneMode = computed(() => !!getters["teacherRoom/getStudentModeOneId"]);
    const anotherTeacherIsInOneToOneMode = computed(() => !!getters["teacherRoom/getStudentModeOneWithAnotherTeacherId"]);
    const isOneToOneWithHelperIgnoreTeacherVoice = computed(() => getters["classTeaching/getOneToOneWithHelperIgnoreTeacherVoice"]);
    const signalRConnected = computed(() => getters["teacherRoom/signalRConnected"]);
    const contextMenuVisibility = ref(false);
    const toggleContextMenu = () => {
      contextMenuVisibility.value = !contextMenuVisibility.value;
    };
    const hideContextMenu = () => {
      contextMenuVisibility.value = false;
    };
    const isAudioPublished = computed<boolean>(() => isUserPublishedAudio({ userId: props.teacher.id, gettersOrRootGetters: getters }));
    const isVideoPublished = computed<boolean>(() => isUserPublishedVideo({ userId: props.teacher.id, gettersOrRootGetters: getters }));
    const audioIcon = computed(() => (isAudioPublished.value ? IconAudioOn : IconAudioOff));
    const videoIcon = computed(() => (isVideoPublished.value ? IconVideoOn : IconVideoOff));

    const loginInfo: LoginInfo = getters["auth/getLoginInfo"];
    const userId = loginInfo.profile.sub;

    // TODO: Combine updateMicOpen and toggleAudio into one
    const updateMicOpen = async (micOpen: boolean) => {
      await toggleMicrophone(async () => {
        await dispatch("teacherRoom/setTeacherAudio", {
          id: props.teacher?.id,
          enable: micOpen,
        });
      });
    };
    const toggleAudio = async () => {
      await toggleMicrophone(async () => {
        const { id } = props.teacher;
        await dispatch("teacherRoom/setTeacherAudio", {
          id,
          enable: !isAudioPublished.value,
        });
      });
    };

    // TODO: Combine updateCamOpen and toggleVideo into one
    const updateCamOpen = async (camOpen: boolean) => {
      await toggleCamera(async () => {
        await dispatch("teacherRoom/setTeacherVideo", {
          id: props.teacher?.id,
          enable: camOpen,
        });
      });
    };
    const toggleVideo = async () => {
      await toggleCamera(async () => {
        const { id } = props.teacher;
        await dispatch("teacherRoom/setTeacherVideo", {
          id,
          enable: !isVideoPublished.value,
        });
      });
    };

    const isMeHelper = computed(() => getters["teacher/isHelper"]);
    const isLowBandWidth = computed(() => getters["getLowBandWidth"]);
    const avatarTeacher = computed(() => generateAvatar(props.teacher?.avatar));
    const isSupportedVideo = computed(() => !!(window as any).chrome && !(typeof SharedArrayBuffer === "function"));
    const oneToOneStudentId = computed<boolean>(() => getters["teacherRoom/getStudentModeOneId"]);
    const toggleTeacherVoiceShown = computed(() => isMeHelper.value && oneToOneStudentId.value);
    const openDeviceSettingsModal = () => {
      isDeviceSettingsModalShown.value = true;
    };

    const openDeviceWarningModal = async (isCamera: boolean) => {
      await dispatch(vuexName(VuexNames.MODAL.DISPATCHES.INFORM_DEVICE_WARNING), {
        code: isCamera ? cameraError.value.code : microphoneError.value.code,
        isCamera,
        callback: isCamera ? toggleVideo : toggleAudio,
      });
    };

    watch(isVideoPublished, async (val) => {
      if (val && dynamicModalName.value === DynamicModalNames.CameraWarning) {
        commit("modal/close");
      }
    });

    watch(isAudioPublished, async (val) => {
      if (val && dynamicModalName.value === DynamicModalNames.MicrophoneWarning) {
        commit("modal/close");
      }
    });

    const toggleOneToOneWithHelperIgnoreTeacherVoice = debounce(async () => {
      await dispatch("teacherRoom/toggleOneToOneWithHelperIgnoreTeacherVoice", !isOneToOneWithHelperIgnoreTeacherVoice.value);
    }, 50);
    const isTeacherCalling = computed(() => getters["calling/checkCallingHasUserById"](props.teacher.id));
    const isAvatarShown = computed(() => {
      if (!isJoinedSession.value) return false;
      if (isMeHelper.value) {
        return isTeacherCalling.value && (!isVideoPublished.value || anotherTeacherIsInOneToOneMode.value);
      } else {
        return !isVideoPublished.value;
      }
    });

    return {
      audioIcon,
      videoIcon,
      toggleAudio,
      toggleVideo,
      contextMenuVisibility,
      toggleContextMenu,
      hideContextMenu,
      isLowBandWidth,
      IconLowWifi,
      IconCog,
      avatarTeacher,
      isSupportedVideo,
      userId,
      isMeHelper,
      signalRConnected,
      isDeviceSettingsModalShown,
      openDeviceSettingsModal,
      updateMicOpen,
      updateCamOpen,
      isVideoPublished,
      isAudioPublished,
      toggleTeacherVoiceShown,
      isInOneToOneMode,
      isOneToOneWithHelperIgnoreTeacherVoice,
      toggleOneToOneWithHelperIgnoreTeacherVoice,
      anotherTeacherIsInOneToOneMode,
      VideoCameraIcon,
      VideoCameraSlashIcon,
      SpeakerWaveIcon,
      SpeakerXMarkIcon,
      videoRef,
      spinnerVisible,
      isAvatarShown,
      cameraError,
      microphoneError,
      isJoinedSession,
      isTeacherCalling,
      isMicrophoneToggling,
      isCameraToggling,
      openDeviceWarningModal,
    };
  },
});
