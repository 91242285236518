import { GetterTree } from "vuex";
import { ModalAction, ModalState } from "./state";
import { defineVuexName, VuexNames } from "@/store/utils";

const getters: GetterTree<ModalState, any> = {
  getIsOpen(state: ModalState): boolean {
    return state.isOpen;
  },
  getTitle(state: ModalState): string {
    return state.title;
  },
  getActions(state: ModalState): ModalAction[] | undefined {
    return state.actions;
  },
  getView(state: ModalState): object {
    return state.view;
  },
  getProps(state: ModalState): object {
    return state.props;
  },
  getIsSubmitting(state: ModalState): boolean {
    return state.isSubmitting;
  },
  [defineVuexName(VuexNames.MODAL.GETTERS.NAME)](s: ModalState) {
    return s.name;
  },
};

export default getters;
