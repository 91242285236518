import { createGuid } from "@/utils/utils";
// fabric.js element types
export enum FbObjects {
  Rect = "rect",
  Circle = "circle",
  Triangle = "triangle",
  Line = "line",
  Polygon = "polygon",
  Path = "path",
  Group = "group",
  Text = "text",
  Textbox = "textbox",
  Image = "image",
  Ellipse = "ellipse",
  IText = "i-text",
}
// fabric.js event types
export enum FbEvents {
  MouseMove = "mouse:move", // Fired continuously during a mouse or touch interaction
  MouseDown = "mouse:down", // Fired when a mouse button is pressed down and the mouse is moved
  MouseUp = "mouse:up", // Fired when a mouse button is released after a click
  MouseDownBefore = "mouse:down:before", // Fired when a mouse button is clicked (pressed and released) on an object
  MouseUpBefore = "mouse:up:before", // Fired when a mouse button is released after clicking on an object
  MouseOver = "mouse:over", // Fired when an object is hovered over with the mouse pointer
  MouseOut = "mouse:out", // Fired when the mouse pointer leaves an object
  MouseEnter = "mouse:enter", // Fired when the mouse pointer enters the canvas
  MouseLeave = "mouse:leave", // Fired when the mouse pointer leaves the canvas
  MouseWheel = "mouse:wheel", // Fired when the mouse wheel is scrolled
  KeyDown = "key:down", // Fired when a key is pressed down
  KeyUp = "key:up", // Fired when a key is released
  CanvasResize = "canvas:resize", // Fired when the canvas is being resized
  ObjectSelected = "object:selected", // Fired when an object is selected
  ObjectDeselected = "object:deselected", // Fired when an object is deselected
  ObjectMoving = "object:moving", // Fired when an object is moved
  ObjectScaling = "object:scaling", // Fired when an object is scaled
  ObjectRotating = "object:rotating", // Fired when an object is rotated
  ObjectModified = "object:modified", // Fired when an object is modified (moved, scaled, rotated, etc.)
  ObjectAdded = "object:added", // Fired when an object is added to the canvas
  ObjectRemoved = "object:removed", // Fired when an object is removed from the canvas
  TextEditingEntered = "text:editing:entered", // Fired when a text object enters editing mode
  TextEditingExited = "text:editing:exited", // Fired when a text object exits editing mode
  TextSelectionChanged = "text:selection:changed", // Fired when the selection within a text object changes
  TextChanged = "text:changed", // Fired when the text within a text object changes
  SelectionCleared = "selection:cleared", // Fired when the selection is cleared
  SelectionCreated = "selection:created", // Fired when a selection is created
  SelectionUpdated = "selection:updated", // Fired when a selection is updated
  BeforeSelectionCleared = "before:selection:cleared", // Fired before the selection is cleared
  ObjectMouseDown = "mousedown",
}
export const DEFAULT_TEXT_BOX_PROPS = {
  textAlign: "left",
  padding: 0,
  originY: "bottom",
};

export const hasHighlightedText = (target: any) => {
  if (target?.type !== FbObjects.Textbox) return false;
  return target.selectionEnd - target.selectionStart > 0;
};
// This function is used to assign a unique identifier to a fabric.js element when it is created
export const assignUniquePropertiesToNewlyCreatedCanvasObject = (element: any, id?: string) => {
  if (!getUniqueIdFromFabricElement(element)) {
    element.objectId = id ? id : createGuid();
  }
  // To make the code simpler, we add createdOrder is Date.now() rather than incrementing number, because we don't need to keep track of the order of creation
  // The size is not problem because we not too much element in the canvas at the same time
  if (!getUniqueCreatedOrderFromFabricElement(element)) {
    element.createdOrder = Date.now();
  }
};

export const getUniqueIdFromFabricElement = (element: any) => {
  return element?.objectId;
};
export const getUniqueCreatedOrderFromFabricElement = (element: any) => {
  return element?.createdOrder;
};

// When we JSON.stringify a fabric.js element, all the custom properties are lost. This function is used to keep these properties we want when serializing a fabric.js element
export const serializeFabricElementWithCustomAttributes = (element: any) => {
  return element?.toJSON(["objectId", "createdOrder", "customAttributes"]);
};

export const findLatestCreatedItem = (items: any[]) => {
  return items.sort((a, b) => b.createdOrder - a.createdOrder)[0];
};

export enum ConnectObjectTypes {
  Target = "annotation-lesson",
  LessonBackground = "lesson-img",
}
export const isTargetObject = (target: any) => target.id === ConnectObjectTypes.Target;
export const isLessonBackgroundObject = (target: any) => target.id === ConnectObjectTypes.LessonBackground;
export const isLaserPathObject = (target: any) => target.isLaserPath;
export const getAllObjectsExceptBackgroundAndTargets = (canvas: any) => {
  return canvas.getObjects().filter((obj: any) => !isTargetObject(obj) && !isLessonBackgroundObject(obj));
};
export const getAllUndoableObjects = (canvas: any) => {
  return canvas.getObjects().filter((obj: any) => !isTargetObject(obj) && !isLessonBackgroundObject(obj) && !isLaserPathObject(obj));
};

export const getAllObjectsConsideredAsShapes = (canvas: any) =>
  getAllObjectsExceptBackgroundAndTargets(canvas).filter((obj: any) => obj.type == FbObjects.Circle || obj.type == FbObjects.Rect);

export enum FbObjectOrigins {
  Center = "center",
  Left = "left",
  Right = "right",
  Top = "top",
  Bottom = "bottom",
}

export const getFixedStrokeSizeProperties = () => ({
  strokeUniform: true,
  noScaleCache: false,
});

export const getFourPoints = (point: { x: number; y: number }) => {
  const DIFF = 0.5;
  return [
    { x: point.x, y: point.y },
    { x: point.x, y: point.y + DIFF },
    { x: point.x + DIFF, y: point.y + DIFF },
    { x: point.x + DIFF, y: point.y },
    { x: point.x, y: point.y },
  ];
};

export const isObjectExist = (canvas: any, id: string) => {
  return canvas.getObjects().findIndex((obj: any) => obj.objectId === id) > -1;
};
