import { computed, defineComponent } from "vue";
import { CameraCustomErrorCode, MicrophoneCustomErrorCode } from "@/agora/utils";
import { fmtMsg } from "vue-glcommonui";
import { MediaDeviceErrors } from "@/locales/localeid";

export default defineComponent({
  name: "DeviceWarningContent",
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      CameraCustomErrorCode,
      MicrophoneCustomErrorCode,

      MsgCameraInUseDetailed1: computed(() => fmtMsg(MediaDeviceErrors.CameraInUseDetailed1)),
      MsgCameraInUseDetailed2: computed(() => fmtMsg(MediaDeviceErrors.CameraInUseDetailed2)),
      MsgCameraPermissionDeniedDetailed2: computed(() => fmtMsg(MediaDeviceErrors.CameraPermissionDeniedDetailed2)),
      MsgCameraNotFoundDetailed: computed(() => fmtMsg(MediaDeviceErrors.CameraNotFoundDetailed)),

      MsgMicrophoneInUseDetailed1: computed(() => fmtMsg(MediaDeviceErrors.MicrophoneInUseDetailed1)),
      MsgMicrophoneInUseDetailed2: computed(() => fmtMsg(MediaDeviceErrors.MicrophoneInUseDetailed2)),
      MsgMicrophonePermissionDeniedDetailed2: computed(() => fmtMsg(MediaDeviceErrors.MicrophonePermissionDeniedDetailed2)),

      MsgMicrophoneNotFoundDetailed: computed(() => fmtMsg(MediaDeviceErrors.MicrophoneNotFoundDetailed)),
      MsgMicrophoneEnableNoiseCancellingFailedDetailed: computed(() => fmtMsg(MediaDeviceErrors.MicrophoneEnableNoiseCancellingFailedDetailed)),

      MsgDeviceReopenBrowser: computed(() => fmtMsg(MediaDeviceErrors.DeviceReopenBrowser)),
      MsgDeviceReloadPage: computed(() => fmtMsg(MediaDeviceErrors.DeviceReloadPage)),
      DevicePressOnIcon: computed(() => fmtMsg(MediaDeviceErrors.DevicePressOnIcon)),
    };
  },
});
